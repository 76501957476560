<template>
  <div
    class="overlay-idle"
    id="modalIdle"
    @click="$emit('idleModalClosed')"
  >
    <div class="modal-idle">
        <button type="button" class="close" @click="$emit('idleModalClosed')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
      <div class="modal-idle-title d-flex align-items-center justify-content-between">
        <span>Session Expired</span>
      </div>
      <div class="p-3"> 
        <p>You've been logged out due to being idle for 30 minutes</p>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/eventBus";
export default {
  props: ["is_idle"],
  data() {
    return {
    }
  },


}
</script>

<style>
.overlay-idle {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-idle {
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  background: white;
  padding: 12px;
}
.modal-idle-title {
  color: #38404f;
  font-weight: bold;
  padding: 16px;
}
</style>