
const bankRoutes = [

  /*staff */
  {
    path: '/bank/staff',
    name: 'bank-staff',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/bank/staff/StaffAccounts.vue')
  },
  {
    path: '/bank/staff/create',
    name: 'bank-staff-create',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/bank/staff/Create.vue')
  },
  {
    path: '/bank/staff/:id',
    name: 'bank-staff-edit',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/bank/staff/Edit.vue')
  },

  /* LMT */
  {
    path: '/bank/lmt',
    name: 'bankuser-lmt',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/bank/lmt/Lmts.vue')
  },

  {
    path: '/bank/lmt/request',
    name: 'bank-lmt-request',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/bank/lmt/Request.vue')
  },
  {
    path: '/bank/lmt/create/:id',
    name: 'bank-lmt-create',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/bank/lmt/Create.vue')
  },
  {
    path: '/bank/lmt/:id',
    name: 'bank-lmt-profile',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/bank/lmt/Details.vue')
  },

  /*Agents */
  {
    path: '/bank/agents',
    name: 'bank-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/bank/agent/Agents.vue')
  },
  {
    path: '/bank/agents/:id',
    name: 'bank-agent-profile',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/bank/agent/Details.vue')
  },


  /*CTRs*/
  {
    path: '/bank/ctr',
    name: 'bank-ctr',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/bank/ctr/Ctrs.vue')
  },
  {
    path: '/bank/ctr/lmt',
    name: 'bank-ctr-lmt',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/bank/ctr/Lmt.vue')
  },
  {
    path: '/bank/ctr/agent',
    name: 'bank-ctr-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/bank/ctr/Agents.vue')
  },

  /*IDs*/
  {
    path: '/bank/id',
    name: 'bank-id',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "id" */ '../views/bank/id/Ids.vue')
  },
  {
    path: '/bank/id/lmt',
    name: 'bank-id-lmt',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "id" */ '../views/bank/id/Lmts.vue')
  },
  {
    path: '/bank/id/agent',
    name: 'bank-id-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "id" */ '../views/bank/id/Agents.vue')
  },

  /*Transaction report*/
  {
    path: '/bank/reports',
    name: 'bank-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/transactions/Report.vue')
  },
  {
    path: '/bank/reports/transaction',
    name: 'banktransaction-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/transactions/Report.vue')
  },

  /*Deposit report*/
  {
    path: '/bank/reports/deposit',
    name: 'bank-deposit-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/deposit/Report.vue')
  },
  {
    path: '/bank/reports/deposit/details/:bank_lmt_id/:deposit_type_id/:date',
    name: 'bank-deposit-reports-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/deposit/Details.vue')
  },
  {
    path: '/bank/reports/deposit/agents/:deposit_id',
    name: 'bank-deposit-report-agent-info',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/deposit/Agents.vue')
  },
  {
    path: '/bank/reports/deposit/agents/details/:deposit_id/:agent_id',
    name: 'bank-deposit-report-details',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/deposit/AgentsDetails.vue')
  },
  {
    path: '/bank/reports/deposit/agents/details/:deposit_id/:agent_id/info',
    name: 'bank-deposit-report-full',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/deposit/FullDeposit.vue')
  },

  /*Agent Receivable report*/
  {
    path: '/bank/reports/receivable',
    name: 'bank-receivable-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/receivable/Report.vue')
  },
  {
    path: '/bank/reports/receivable/details/:id',
    name: 'bank-receivable',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/receivable/Details.vue')
  },
  {
    path: '/bank/reports/receivable/details/:bank_lmt_id/:bank_account_id',
    name: 'bank-receivable-info',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/receivable/Info.vue')
  },
  {
    path: '/bank/reports/receivable/details/:bank_lmt_id/:bank_account_id/:agent_id',
    name: 'bank-receivable-info-details',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/receivable/ReportDetails.vue')
  },

  /*Redflags report*/
  {
    path: '/bank/reports/redflags',
    name: 'bank-redflags-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/bank/reports/red-flags/Report.vue')
  },

  /*statistics report*/
  {
    path: '/bank/reports/statistics',
    name: 'bank-statistics-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "statistics" */ '../views/bank/reports/statistics/Report.vue')
  },

  /*Red Flags*/
  {
    path: '/bank/red-flags',
    name: 'bank-red-flags',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/bank/red-flags/Redflags.vue')
  },
  {
    path: '/bank/red-flags/:bank_lmt_id',
    name: 'bank-red-flags-lmt',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/bank/red-flags/LMT.vue')
  },
  {
    path: '/bank/red-flags/details/:red_flag_id',
    name: 'bank-red-flag',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/bank/red-flags/Redflag.vue')
  },
  {
    path: '/bank/deposit',
    name: 'bank-deposit-confirm',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "deposit-confirm" */ '../views/bank/deposit/Confirm.vue')
  },

  //EDD
  {
    path: '/bank/edd/create',
    name: 'bank-edd-create',
    meta: { middlewareAuth: true, middlewareNotCompany: true },
    component: () => import(/* webpackChunkName: "edd" */ '../views/bank/edd/Create.vue')
  },
  {
    path: '/bank/edd/print',
    name: 'bank-edd-print',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "edd" */ '../views/bank/edd/Print.vue')
  },
  {
    path: '/bank/profile',
    name: 'bank-profile',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "bank" */ '../views/bank/Profile.vue')
  },


]
export default bankRoutes;