

class Auth {
    constructor(axios, EventBus) {
        //debugger;
        this.axios = axios;
        this.EventBus = EventBus;
        this.token = window.localStorage.getItem('token');
        let userData = window.localStorage.getItem('user');
        this.user = userData ? JSON.parse(userData) : null;
        if (this.token) {
            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }

    }

    login(token, user, role, role_data) {

        window.localStorage.setItem('token', token);
        window.localStorage.setItem('role', role);
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('role_data', JSON.stringify(role_data));
        window.sessionStorage.setItem('logged', true);

        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.token = token;
        this.user = user;

        this.EventBus.emit('userLoggedIn',{resetted: user.resetted});
    }

    check() {
        let localtoken = window.localStorage.getItem('token');

        if (!(this.token == localtoken && localtoken != null)) {
            //  Event.$emit('userLoggedOut');
        }

        return (this.token == localtoken && localtoken != null);
    }

    logout() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('data');
        window.localStorage.removeItem('role');
        window.sessionStorage.removeItem('logged');
        this.axios.defaults.headers.common['Authorization'] = null;
        this.token = null;
        this.user = null;

        this.EventBus.emit('userLoggedOut');
    }
    silentLogout() {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('role');
        window.sessionStorage.removeItem('logged');
        this.axios.defaults.headers.common['Authorization'] = null;
    }
    getName() {

        let userData = window.localStorage.getItem('data');
        let user = userData ? JSON.parse(userData) : null;

        let userData1 = window.localStorage.getItem('user');
        let user1 = userData1 ? JSON.parse(userData1) : null;

        if (user1) { return user1.name }


        if (!user) { return '' }
        return user.name;
    }
    getRoleData(){

        let roleData = window.localStorage.getItem('role_data');
        let user = roleData ? JSON.parse(roleData) : null;
       
        return user;
    }
    getEmail() {
        let userData = window.localStorage.getItem('user');
        let user = userData ? JSON.parse(userData) : null;
        if (!user) { return '' }
        return user.email;
    }
    getUser() {
        let userData = window.localStorage.getItem('user');
        let user = userData ? JSON.parse(userData) : null;
        if (!user) { return '' }
        return user;
    }
    getID() {
        let userData = window.localStorage.getItem('user');
        let user = userData ? JSON.parse(userData) : null;
        if (!user) { return '' }
        return user.id;
    }
    getRole() {
        let role = window.localStorage.getItem('role');
        return role;
    }
    getPasswordStatus() {
        let userData = window.localStorage.getItem('user');
        let user = userData ? JSON.parse(userData) : null;
        if (!user) { return '' }
        return !user.resetted;
    }
    getSubRole() {
        let userData = window.localStorage.getItem('user');
        let data = userData ? JSON.parse(userData) : null;
        return data && data.subrole ? data.subrole : { name: '' };
    }
    setName(f, l) {
        let userData = window.localStorage.getItem('user');
        let user = userData ? JSON.parse(userData) : null;
        user.first_name = f;
        user.last_name = l;
        window.localStorage.setItem('user', JSON.stringify(user));
    }
}

export default Auth;
