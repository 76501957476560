// src/event.js
import mitt from "mitt";

const EventBus = mitt();


const idleTime = 1800000; // 3 seconds

let idleTimer;

const resetIdleTimer = () => {
  clearTimeout(idleTimer);
  idleTimer = setTimeout(() => {
    EventBus.emit("user-idle");
  }, idleTime);
};

// Add event listeners for user activity
["mousemove", "keydown", "mousedown", "touchstart", "touchmove", "mousewheel"].forEach((event) => {
  window.addEventListener(event, resetIdleTimer);
});

// Initialize idle timer
resetIdleTimer();


export default EventBus;
//console.log("Mitt instance created:", EventBus);
