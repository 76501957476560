<template>
  <div>
    <div
      :class="{ right: right, 'top-set': type === 'upload' }"
      class="md-dialog delete md-theme-default"
      md-active="true"
    >
      <div class="md-dialog-container" tabindex="-1">
        <div class="md-dialog-content md-theme-default">
          Are you sure you want to delete this {{ type }}?
        </div>
        <div class="md-dialog-actions">
          <el-button @click="cancel" class="md-primary border-0 p-0 mr-2 hover-none">Cancel</el-button>
          <el-button @click="confirm" class="md-accent border-0 p-0 hover-none">Yes, Delete</el-button>
        </div>
      </div>
    </div>
    <div class="md-overlay md-fixed md-dialog-overlay"></div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'confirm'], // Declare emitted events
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss">
.right {
  position: absolute;
  right: 0px;
  top: 150px;
  z-index: 100;
  max-width: 300px;
  margin-right: 0;
  transform: translate(-7%, -50%);
}
</style>