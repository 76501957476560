<template>
  <el-form-item
  :label="label"
  :id="property"
  v-bind:class="{'is-error': form.err.has(property) }"
  label-position="top" 
  size="large"
   class="flex-column"
  >
    <el-input
      :disabled="disabled"
      v-on:input="form.err.clear(property),$emit('update',$event)"
      :name="property"
      v-model="form.formData[property]"
      v-if="!type"
    />
    <el-input
      v-else
      :disabled="disabled"
      v-on:input="form.err.clear(property),$emit('update',$event)"      
      :name="property"
      v-model="form.formData[property]"
      :type="type"
    />

    <span class="el-form-item__error">{{form.err.get(property)}}</span>
  </el-form-item>
</template>
<script>
export default {
  props: ["label", "form", "property", "disabled", "type"]
};
</script>