<template>
  <div class="c-tabs mt-2">
    <el-tabs v-if="type=='bank'" v-model="activeTab" @tab-click="handleTabClick" type="card">
      <el-tab-pane 
        label="Transaction Report" 
        :name="`/${type || 'owner'}/reports/transaction`"
      />
      <el-tab-pane 
        label="Deposit Report" 
        :name="`/${type || 'owner'}/reports/deposit`" 
      />
      <el-tab-pane 
        label="Agent Receivable Report" 
        :name="`/${type || 'owner'}/reports/receivable`" 
      />
      <el-tab-pane 
        label="Redflags" 
        :name="`/${type || 'owner'}/reports/redflags`" 
      />
      <el-tab-pane 
        label="Statistics" 
        :name="`/${type || 'owner'}/reports/statistics`" 
      />
    </el-tabs>
    <el-tabs v-else-if="type=='lmt'" v-model="activeTab" @tab-click="handleTabClick" type="card">
      <el-tab-pane 
        label="Transaction Report" 
        :name="`/${type || 'owner'}/reports/transaction`"
      />
      <el-tab-pane 
        label="Deposit Report" 
        :name="`/${type || 'owner'}/reports/deposit`" 
      />
    </el-tabs>
    <el-tabs v-else v-model="activeTab" @tab-click="handleTabClick" type="card">
      <el-tab-pane 
        label="Transaction Report" 
        :name="`/${type || 'owner'}/reports/transaction`"
      />
      <el-tab-pane 
        label="Deposit Report" 
        :name="`/${type || 'owner'}/reports/deposit`" 
      />
      <el-tab-pane 
        label="Agent Receivable Report" 
        :name="`/${type || 'owner'}/reports/receivable`" 
      />
      <el-tab-pane 
        label="Redflags" 
        :name="`/${type || 'owner'}/reports/redflags`" 
      />
      <el-tab-pane 
        label="Statistics" 
        :name="`/${type || 'owner'}/reports/statistics`" 
      />
    </el-tabs>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  props: {
    type: {
      type: String,
      default: "owner", // Default to "owner" if no type is provided
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    // Reactive state for activeTab
    const activeTab = ref("");

    // Function to update activeTab based on URL
    const updateActiveTab = (path) => {
      const baseRoute = `/${props.type}/reports/`;
      if (path === baseRoute) {
        activeTab.value = `${baseRoute}transaction`; // Default to Transaction Report tab
        //console.log(activeTab.value);
      } else {
        activeTab.value = path || `${baseRoute}transaction`;
      }
    };

    // Function to handle tab click
    const handleTabClick = (tab) => {
      const routeName = tab.props?.name;
      if (routeName) {
        router.push(routeName).catch((error) => {
          console.error("Navigation error:", error);
        });
      } else {
        console.error("Invalid route:", tab);
      }
    };

    // Watch for route changes to update activeTab
    watch(
      () => route.path,
      (newPath) => {
        updateActiveTab(newPath);
      },
      { immediate: true }
    );

    // Sync activeTab on component mount
    onMounted(() => {
      updateActiveTab(route.path);
    });

    return {
      activeTab,
      handleTabClick,
    };
  },
};
</script>

<style>
.c-tabs {
  margin-top: 2rem;
}

.hr-line {
  margin-top: 20px;
  border: none;
  border-top: 1px solid #ccc;
}
.el-tabs--card>.el-tabs__header .el-tabs__nav {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
