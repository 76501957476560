<template>
  <div>
    <section class="search_area"></section>
    <!-- menu -->
    <!-- topBar -->
    <div class="topbar">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6"></div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="contact-info">
              <span>
                <i class="fas fa-phone-square"></i> Phone: 800-617-5410
              </span>
              <span>|</span>

              <a
                href="https://twitter.com/depositslipsllc"
                class
                target="_blank"
              >
                <i class="fab fa-twitter-square"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END topBar -->
    <header class="main_menu_area">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <a
          class="navbar-brand"
          href="https://depositslipsapp.com/auth/login#"
        ></a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="barsBtn">
            <i class="fas fa-bars"></i>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" data-url="index">
              <a class="nav-link" href="https://www.depositslipsllc.com/"
                >HOME</a
              >
            </li>
            <li class="nav-item" data-url="about_us">
              <a
                class="nav-link"
                href="https://www.depositslipsllc.com/about_us.php"
                >ABOUT US</a
              >
            </li>
            <li class="nav-item" data-url="product">
              <a
                class="nav-link"
                href="https://www.depositslipsllc.com/product.php"
                >PRODUCT</a
              >
            </li>
            <li class="nav-item" data-url="market">
              <a
                class="nav-link"
                href="https://www.depositslipsllc.com/market.php"
                >MARKET</a
              >
            </li>
            <li class="nav-item" data-url="news">
              <a
                class="nav-link"
                href="https://www.depositslipsllc.com/news.php"
                >NEWS</a
              >
            </li>
            <li class="nav-item" data-url="users">
              <a class="nav-link" href="#">USERS</a>
            </li>
            <!--  <li class="nav-item" data-url="users"><a class="nav-link" href="users.php">USERS</a></li> -->
            <li class="nav-item" data-url="make_deposit">
              <a
                class="nav-link"
                href="http://www.depositslipsapp.com/bank-employee"
                >MAKE A DEPOSIT</a
              >
            </li>
            <!--  <li class="nav-item" data-url="bank-employee"><a class="nav-link" href="bank-employee.php">MAKE A DEPOSIT</a></li> -->
            <li class="nav-item" data-url="contact">
              <a
                class="nav-link"
                href="https://www.depositslipsllc.com/contact.php"
                >CONTACT</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- NAVBAR END  -->
    <!-- end menu -->
    <div id="headerInt" class="headerInt2">
      <div class="overlay">
        <!-- Main -->
        <section id="news">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-1 col-md-3 col-lg-3 col-xl-3"></div>
              <div class="col-12 col-sm-11 col-md-6 col-lg-6 col-xl-6">
                <div class="login-form">
                  <div class="main-div">
                    <div class="panel">
                      <h3 class="authTit text-center">Password reset</h3>
                      <p class="loginTxt">
                        Please reset your password before you continue
                      </p>
                    </div>

                    <form class="form-horizontal" @submit.prevent="onSubmit" @keydown="form.err.clear($event.target.name)">
                      <b-alert variant="danger" :show="fail">{{ message }}</b-alert>
                      <b-alert variant="success" :show="success">{{ message }}</b-alert>

                      <div class="form-group">
                        <div class="input-group">
                          <input
                            type="password"
                            name="current_password"
                            v-model="form.formData.current_password"
                            class="form-control"
                            placeholder="Current Password"
                            autocomplete="off"
                          />
                        </div>
                        <div class="invalid" v-if="form.err.has('current_password')">
                          {{ form.err.get('current_password') }}
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <input
                            type="password"
                            name="password"
                            v-model="form.formData.password"
                            class="form-control"
                            placeholder="New Password"
                            autocomplete="off"
                          />
                        </div>
                        <div class="invalid" v-if="form.err.has('password')">
                          {{ form.err.get('password') }}
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <input
                            type="password"
                            name="password_confirmation"
                            v-model="form.formData.password_confirmation"
                            class="form-control"
                            placeholder="Confirm Password"
                            autocomplete="off"
                          />
                        </div>
                        <div class="invalid" v-if="form.err.has('password_confirmation')">
                          {{ form.err.get('password_confirmation') }}
                        </div>
                      </div>

                      <div class="form-actions">
                        <button type="submit" class="el-button el-button--primary el-button--large custom-btn border-0 btn-send" :disabled="submitting">
                          <span v-if="submitting">Submitting...</span>
                          <span v-else>RESET</span>
                        </button>
                        <el-button size="large" @click="logout" class="border-0 float-right">Cancel</el-button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-1 col-md-3 col-lg-3 col-xl-3"></div>
          </div>
        </section>
      </div>
    </div>

    <!-- END Main -->

    <!-- footer -->
    <footer>
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <img src="../assets/static/logo.png" alt="DepositSlips LLC" />
              <br />
              <p class="contactIcons">
                <i class="fa fa-phone white"></i>800-617-5410
              </p>
              <p class="contactIcons">
                <i class="fa fa-envelope white"></i
                >administrator@depositslipsllc.com
              </p>
            </div>
            <div class="col-md-2">
              <h5>THIS SITE</h5>
              <ul>
                <li>
                  <a href="https://www.depositslipsllc.com/about_us.php"
                    >About US</a
                  >
                </li>
                <li>
                  <a href="https://www.depositslipsllc.com/product.php"
                    >Our Product</a
                  >
                </li>
                <li>
                  <a href="https://www.depositslipsllc.com/market.php"
                    >Target Market</a
                  >
                </li>
                <li>
                  <a href="https://www.depositslipsllc.com/news.php">News</a>
                </li>
                <li>
                  <a href="https://www.depositslipsllc.com/contact.php"
                    >Contact Us</a
                  >
                </li>
                <li>
                  <a href="#">Users</a>
                </li>
                <!-- <li><a href="users.php">Users</a></li> -->
                <li>
                  <a href="http://www.depositslipsapp.com/bank-employee"
                    >Make a Deposit</a
                  >
                </li>
                <!-- <li><a href="bank-employee.php">Make a Deposit</a></li> -->
              </ul>
            </div>
            <div class="col-md-2">
              <h5>LEGAL</h5>
              <ul>
                <!-- <li><a href="#">User Agreement</a></li> -->
                <li>
                  <a href="https://depositslipsapp.com/auth/privacy-policy.php"
                    >Privacy Policy</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-md-4">
              <h5>HELPFUL LINKS</h5>
              <ul>
                <li>
                  <a href="http://www.dfs.ny.gov/"
                    >NY Dept. of Financial Services</a
                  >
                </li>
                <li>
                  <a href="http://www.state.nj.us/dobi/index.html"
                    >NJ Dept. of Banking and Insurance</a
                  >
                </li>
                <li>
                  <a href="http://www.ct.gov/dob"
                    >Connecticut Department of Banking</a
                  >
                </li>
                <li>
                  <a href="https://www.fincen.gov/">FinCEN</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <div class="subfooter">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
            <p>Copyright © 2025. DepositSlips LLC. All rights reserved</p>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2">
            <a
              href="https://twitter.com/depositslipsllc"
              class="socialIcons"
              target="_blank"
            >
              <i class="fab fa-twitter-square"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "../core/Form";
import EventBus from '@/eventBus';

export default {
  data() {
    return {
      form: new Form({
        current_password: '',
        password: '',
        password_confirmation: '',
      }),
      success: false,
      fail: false,
      message: '',
      submitting: false,
    };
  },
  methods: {
      logout() {
      auth.logout();
    },
       onSubmit() {
      var $this = this;
      $this.form
        .submit("post", "/user/password-change ")
        .then(function (response) {
          let data = response.data;
          let userData = window.localStorage.getItem("user");
          let user = userData ? JSON.parse(userData) : null;
          user.resetted = 1;
          window.localStorage.setItem("user", JSON.stringify(user));
          $this.$emit('updated')
        });
    },
  }
};
</script>
<style>
@import "../assets/static/all.css";
@import "../assets/static/bootstrap.min.css";
@import "../assets/static/styles.css";
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-Regular.woff2")
    format("woff2");
}
@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-Italic.woff2")
    format("woff2");
}

@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-Medium.woff2")
    format("woff2");
}
@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-MediumItalic.woff2")
    format("woff2");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-Bold.woff2")
    format("woff2");
}
@font-face {
  font-weight: 700;
  font-style: italic;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-BoldItalic.woff2")
    format("woff2");
}

@font-face {
  font-weight: 900;
  font-style: normal;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-Black.woff2")
    format("woff2");
}
@font-face {
  font-weight: 900;
  font-style: italic;
  font-family: "Inter-Loom";

  src: url("https://cdn.loom.com/assets/fonts/inter/Inter-UI-BlackItalic.woff2")
    format("woff2");
}
</style>
