<template>
  <div style="position: relative">
    <el-button
      v-if="type === 'dropdown'"
      @click="open"
      class="text-black button-text border-0"
    >
      <span class="contain-text">
        <span v-if="startRange">
          {{ startRange + " to " + endRange }}
          <span
            @click.prevent="clear"
            class="clear-date"
          >
            &times;
          </span>
        </span>
        <span v-else>{{ text }}: All</span>
        <i :class="['fas', show ? 'fa-chevron-up' : 'fa-chevron-down', 'pl-1']"></i>
      </span>
    </el-button>

    <div v-else class="position-relative">
      <el-input
        size="large"
        readonly
        @focus="show = true"
        v-model="rangeText"
        class="range-input"
        :placeholder="text"
      />
      <span
        v-if="startRange"
        @click.prevent="clear"
        class="clear-date"
      >
        &times;
      </span>
      <i v-else class="custom-icon">
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 10l5 5 5-5z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </i>
    </div>

    <el-dialog v-model="show" v-if="toggleCalendar"  custom-class="custom-dailog datepicker">
      <el-date-picker
        v-show="show"
        v-model="range"
        type="daterange"
        range-separator="to"
        start-placeholder="Start date"
        end-placeholder="End date"
        @change="onChange"
        class="calendar"
        format="YYYY/MM/DD"
        value-format="YYYY-MM-DD"
      />
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";

export default {
  props: {
    text: String,
    type: String,
  },
  setup(props, { emit }) {
    const show = ref(false);
    const startRange = ref("");
    const endRange = ref("");
    const range = ref([]);

    const open = () => {
      show.value = true;
      emit("close-filters", "date-range");
    };

    const toggleCalendar = () => {
      show.value = !show.value;
    };

    const onChange = (value) => {
      range.value = value;
      startRange.value = value ? value[0] : "";
      endRange.value = value ? value[1] : "";
      setTimeout(() => {
        show.value = false; // Close the dialog
      }, 1000);
      emit("input", [startRange.value, endRange.value]);

    };

    const clear = () => {
      range.value = [];
      startRange.value = "";
      endRange.value = "";
      show.value = false;
      emit("input", [startRange.value, endRange.value]);
    };

    const rangeText = computed(() => {
      return startRange.value && endRange.value
        ? `${startRange.value} - ${endRange.value}`
        : null;
    });

    onMounted(() => {
      window.addEventListener("clear-filters", () => {
        clear();
      });

      window.addEventListener("close-filters", (event) => {
        if (event.detail !== "date-range") {
          show.value = false;
        }
      });
    });

    return {
      show,
      startRange,
      endRange,
      range,
      open,
      toggleCalendar,
      onChange,
      clear,
      rangeText,
    };
  },
};
</script>

<style>

i.custom-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.el-dialog.custom-dailog.datepicker {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 392px;
}
span.clear-date {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>

