<template>
  <div :key="id">
    <div v-if="type != 'single'">
      <div class="table-container" v-if="data && data.length > 0">
        <el-table v-if="data && data.length > 0" :data="data">
          <el-table-column :label="text" prop="doc" sortable min-width="150">
            <template #default="{ row, $index }">
              <span>{{ row.doc }}</span>
              <img
                @click="(ditem = row, confirm = true, i = $index)"
                src="../assets/icons/btnRemoveUpload.svg"
                class="pointer ml-2"
                alt="delete"
              />
            </template>
          </el-table-column>

          <el-table-column v-if="type !== 'file'" label="Renewal" prop="renewal" sortable min-width="150">
            <template #default="{ row }">
              {{ row.renewal }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="type !== 'file'"
            :label="type !== 'partial' ? 'State' : ''"
            prop="state"
            sortable
            min-width="150"
          >
            <template #default="{ row }">
              <span v-if="row.state?.name">{{ row.state.name }}</span>
              <span v-else>{{ row.state }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else class="fixed-table">
        <div class="md-content md-table md-theme-default">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
              <thead>
                <tr>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{text}}</div>
                    </div>
                  </th>
                  <th v-if="type!='file'" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">Renewal</div>
                    </div>
                  </th>
                  <th v-if="type!='file'" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div v-if="type!='partial'" class="md-table-head-label">State</div>
                      <div v-else class="md-table-head-label"></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="md-table-row">
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <a @click="new_doc=true" class="cursor-pointer">Upload New</a>

                      <span class="invalid d-inline-block ml-3" v-if="err">{{err[0]}}</span>
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container"></div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a
        v-if="data && data.length>num"
        @click="filter=!filter"
        class="weight-500 ml-4 cursor-pointer"
      >
        Show
        <span v-if="filter">less</span>
        <span v-else>more</span>
      </a>
      <span v-if="data && data.length>0 && type!='partial' ">
        <a @click="new_doc=true" class="cursor-pointer ml-4">Upload New</a>
        <span class="invalid d-inline-block ml-3" v-if="err">{{err[0]}}</span>
      </span>

      <doc-upload
        v-on:cancel="new_doc=false"
        v-on:update="$emit('update',$event),new_doc=false"
        :type="type"
        v-if="new_doc"
      ></doc-upload>
    </div>

    <div v-else>
      <div class="table-responsive fixed-table mb-0">
        <div class="md-content md-table md-theme-default">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table class="table mb-0">
              <thead>
                <tr>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{text}}</div>
                    </div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        <div v-if="type != 'single'" class="md-table-cell-container">
                          <a @click="new_doc=true" class="cursor-pointer">Upload New</a>
                        </div>
                        <div v-else class="md-table-cell-container">
                          <div v-if="hasFile && (data && data[0])" class="cursor-pointer">
                            
                            <span>{{data[0].doc }}</span>
                            <img @click="confirm=true,ditem=data[0]"
                              src="../assets/icons/btnRemoveUpload.svg"
                              class="pointer ml-2 pb-1 height-22"
                              alt="delete"
                            />
                          </div>
                          <span v-else>
                            <a @click="new_doc=true" class="cursor-pointer">Upload New</a>
                            <span class="invalid d-inline-block ml-3" v-if="err">{{err[0]}}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled"></div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="md-table-row border-0">
                  <td class="md-table-cell height-0 border-0 py-0">
                    <div class="md-table-cell-container border-0"></div>
                  </td>
                  <td class="md-table-cell height-0 border-0 py-0">
                    <div class="md-table-cell-container border-0"></div>
                  </td>
                  <td class="md-table-cell height-0 border-0 py-0">
                    <div class="md-table-cell-container border-0"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <doc-upload
        v-on:cancel="new_doc=false"
        v-on:update="$emit('update',$event),new_doc=false"
        v-if="new_doc"
        single="1"
      ></doc-upload>
    </div>
    <confirm type="upload" v-if="confirm" @cancel="confirm=false" @confirm="remove" />
  </div>
</template>
<script>
import axios from 'axios'
import EventBus from '@/eventBus';
import DocUpload from "./bank/DocUpload";
// Confirmation component before deleting user
import ConfirmDelete from "./owner/ConfirmDelete";

export default {
  components: { "doc-upload": DocUpload, confirm: ConfirmDelete },

  props: ["data", "text", "edit", "type", "err"],
  data() {
    return {
      confirm: false,
      filter: false,
      num: 4,
      new_doc: false,
      ddata: [],
      id: 0,
      file: false,
      ditem: "",
      states: [],
      i: 0
    };
  },
  mounted() {
    
    this.ddata = this.data;
    this.checkFile();
    EventBus.on("file_uploaded", () => {
      this.ddata = this.data;
      this.id++;
    });
    EventBus.on("file_removed", () => {
      this.ddata = this.data;
      this.id--;
    });
  },
  updated() {
    this.checkFile();
  },
  created() {
    //load the states for filling the select box
    axios.get("/states").then(({ data }) => {
      this.states = data.states;
    });
  },
  computed: {
    getItems() {
      if (!this.filter && this.ddata.length > 4) {
        return this.ddata.slice(0, this.num);
      } else {
        return this.ddata;
      }
    },
    getKey() {
      if (this.ddata && this.ddata[0]) {
        return this.ddata[0].doc;
      } else {
        return 1;
      }
    },
    hasFile() {
      //console.log("Check File", this.file);
      return this.file;

    }
  },
  methods: {
    getState(state) {
      let name = "";
      this.states.forEach(element => {
        if (element.id == state) {
          name = element.name;
        }
      });
      setTimeout(() => {
        return name;
      }, 2000);
    },
    checkFile() {
        if (this.data && this.data[0] && this.data[0].doc != null) {
          this.file = true;

         
        }
      // setTimeout(() => {
      //   if (this.data && this.data[0] && this.data[0].doc != null) {
      //     this.file = true;
      //   }
      // }, 1000);
    },

    remove() {
      this.confirm = false;
      this.ddata = [];
      this.file = false;
      this.$emit("remove", [this.ditem, this.i]);
    }
  },
  filters: {
    trimDoc: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(value.lastIndexOf("/") + 1);
    }
  }
};
</script>

<style>
.table thead th {
    border-bottom: 0;
}
</style>