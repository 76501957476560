<template>
  <div :key="id">
    <div v-if="type !== 'single'">
      <div class="table-container" v-if="data && data.length > 0">
        <el-table
          v-if="data && data.length > 0"
          :data="data"
          style="width: 100%"
          @selection-change="updateData"
        >
          <el-table-column label="Doc" prop="doc" min-width="150">
            <template #default="{ row, $index }">
              <span>{{ row.doc }}</span>
              <img @click="confirm = true; i = $index; ditem = row" 
                src="../assets/icons/btnRemoveUpload.svg"
                class="pointer ml-2"
                alt="delete"
              />
            </template>
          </el-table-column>

          <el-table-column label="Confirmation Doc" prop="confirmation_doc" min-width="150">
            <template #default="{ row, $index }">
              <span v-if="row.confirmation_doc">{{ row.confirmation_doc }}</span>
              <img @click="confirmDocShow($index, row.state.id)"
                class="pl-2 cursor-pointer"
                src="../assets/icons/iconDropDownEdit.svg"
                alt="edit"
              />
              <span v-if="errors_all['banking_licenses.confirmation_doc.' + $index] && !row.confirmation_doc"
                class="invalid d-inline-block ml-3"
              >
                {{ errors_all['banking_licenses.confirmation_doc.' + $index][0] }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            v-if="type !== 'file'"
            label="Renewal"
            prop="renewal"
            min-width="150"
          >
            <template #default="{ row }">
              {{ row.renewal }}
            </template>
          </el-table-column>

          <el-table-column
            v-if="type !== 'file'"
            :label="type !== 'partial' ? 'State' : ''"
            prop="state"
            min-width="150"
          >
            <template #default="{ row }">
              <span v-if="row.state && row.state.name">
                {{ row.state.name }}
              </span>
              <span v-else>{{ row.state }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- Fallback Table -->
      <div v-else class="fixed-table">
        <div class="md-content md-table md-theme-default">
          <div class="md-content md-table-content md-scrollbar md-theme-default">
            <table>
              <thead>
                <tr>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ text }}</div>
                    </div>
                  </th>
                  <th class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">Confirmation Doc</div>
                    </div>
                  </th>
                  <th v-if="type !== 'file'" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">Renewal</div>
                    </div>
                  </th>
                  <th v-if="type !== 'file'" class="md-table-head">
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div v-if="type !== 'partial'" class="md-table-head-label">
                        State
                      </div>
                      <div v-else class="md-table-head-label"></div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="md-table-row">
                  <td class="md-table-cell">
                    <div class="md-table-cell-container">
                      <a @click="new_doc = true" class="cursor-pointer">Upload New</a>
                      <span class="invalid d-inline-block ml-3" v-if="err">{{ err[0] }}</span>
                    </div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container"></div>
                  </td>
                  <td class="md-table-cell">
                    <div class="md-table-cell-container"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Show More/Less -->
      <a
        v-if="data && data.length > num"
        @click="filter = !filter"
        class="weight-500 ml-4 cursor-pointer"
      >
        Show <span v-if="filter">less</span><span v-else>more</span>
      </a>

      <span v-if="data && data.length > 0 && type !== 'partial'">
        <a @click="new_doc = true" class="cursor-pointer ml-4">Upload New</a>
        <span class="invalid d-inline-block ml-3" v-if="err">{{ err[0] }}</span>
      </span>

      <!-- License Upload -->
      <license-upload
        v-if="new_doc"
        @cancel="new_doc = false"
        @update="$emit('update', $event); new_doc = false"
        :type="type"
      ></license-upload>

      <!-- Confirmation Upload -->
      <confirmation-upload
        v-if="confirm_doc"
        @cancel="confirm_doc = false"
        @update_license_confirm="$emit('update_license_confirm', $event); confirm_doc = false"
        type="partial"
        :license_index="confirm_doc_index"
        :state_id="confirm_state_id"
      ></confirmation-upload>
    </div>
    <confirm type="upload" v-if="confirm" @cancel="confirm=false" @confirm="remove" />
  </div>
</template>
<script>
import EventBus from "@/eventBus";
import LicenseUpload from "./bank/LicenseUpload";
import ConfirmationUpload from "./bank/ConfirmationUpload";
import ConfirmDelete from "./owner/ConfirmDelete";
import axios from "axios";

export default {
  components: {
    "license-upload": LicenseUpload,
    "confirm": ConfirmDelete,
    "confirmation-upload": ConfirmationUpload,
  },

  props: ["data", "text", "edit", "type", "err", "errors_all"],
  data() {
    return {
      confirm: false,
      filter: false,
      num: 4,
      new_doc: false,
      confirm_doc: false,
      confirm_doc_index: "",
      confirm_state_id: "",
      ddata: [],
      id: 0,
      file: false,
      ditem: "",
      states: [],
      i: 0,
    };
  },
  mounted() {
    this.ddata = this.data;
    this.checkFile();
    EventBus.on("file_uploaded", () => {
      this.ddata = this.data;
      this.id++;
    });
    EventBus.on("file_confirm_uploaded", () => {
      this.ddata = this.data;
    });
    EventBus.on("file_removed", () => {
      this.ddata = this.data;
      this.id--;
    });
  },
  updated() {
    this.checkFile();
  },
  created() {
    // Load the states for filling the select box
    axios.get("/states").then(({ data }) => {
      this.states = data.states;
    });
  },
  computed: {
    getItems() {
      if (!this.filter && this.ddata.length > 4) {
        return this.ddata.slice(0, this.num);
      } else {
        return this.ddata;
      }
    },
    getKey() {
      if (this.ddata && this.ddata[0]) {
        return this.ddata[0].doc;
      } else {
        return 1;
      }
    },
    hasFile() {
      return this.file;
    },
  },
  methods: {
    updateData(newData) {
      this.$emit("update:data", newData);
    },
    getState(state) {
      let name = "";
      this.states.forEach(element => {
        if (element.id == state) {
          name = element.name;
        }
      });
      setTimeout(() => {
        return name;
      }, 2000);
    },
    checkFile() {
      setTimeout(() => {
        if (this.ddata && this.ddata[0] && this.ddata[0].doc != null) {
          this.file = true;
        }
      }, 1000);
    },

    remove() {
      this.confirm = false;
      this.ddata = [];
      this.file = false;
      this.$emit("remove", [this.ditem, this.i]);
    },
    confirmDocShow(index, state_id) {
      this.confirm_doc = true;
      this.confirm_doc_index = index;
      this.confirm_state_id = state_id;
    },
  },
  filters: {
    trimDoc: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(value.lastIndexOf("/") + 1);
    },
  },
};
</script>
