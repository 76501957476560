<template>
  <div class="position-dialog ">
    <div class="el-dialog upload-doc">
      <div class="el-dialog__header">
        <span class="popup-title">Banking License Confirmation</span>
        <el-button 
          icon="el-icon-close" 
          class="float-right" 
          type="text" 
          @click="$emit('cancel')">
        </el-button>
      </div>
      <div class="doted p-3 d-flex align-items-center">
        <img src="../../assets/icons/iconUploadDocumentPopup.svg" alt="icon" />
        <span class="d-inline-block px-4">
          You can upload your document here.
          <div class="mt-4">
            <label for="fileElemConfirm" class="cursor-pointer primary-color d-block">
              <span v-if="!doc_confirm">Choose File</span>
              <span class="wrap-text-multiple" v-else>{{ doc_confirm.name }}</span>
              <input type="file" id="fileElemConfirm" ref="fileConfirm" @change="previewFilesConfirm" />
            </label>
          </div>
        </span>
      </div>

      <b-row class="px-3" v-if="owner_ids">
        <b-col md="12">
          <div class="form-group">
            <label>Owner</label>
            <input type="text" class="form-control" v-model="name" />
          </div>
        </b-col>
      </b-row>

      <b-row class="px-3" v-if="!single && type !== 'file' && !owner_ids">
        <b-col md="6" class="top-label">
          <el-form-item label="Renewal Date" size="large">
            <el-date-picker 
              v-model="renewal" 
              type="date" 
              size="large"
              placeholder="Select Renewal Date" 
              :disabled-date="disabledDates"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              @change="validate">
            </el-date-picker>
          </el-form-item>
        </b-col>
        <b-col md="6" v-if="type !== 'partial'">
          <label for="state">State</label>
          <select class="form-select" name="state" id="state" v-model="state">
            <option
              v-for="(item, index) in values"
              :key="index"
              :value="item.id"
            >{{item.name}}</option>
          </select>
        </b-col>
      </b-row>

      <div class="el-dialog__footer">
        <el-button type="primary" size="large" @click="$emit('cancel')">Cancel</el-button>
        <el-button
          type="primary"
          size="large"
          :disabled="single ? isDisable : isDisable || !renewal"
          @click="upload">
          Upload
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";

export default {
  props: ["single", "type", "owner_ids", "license_index", "state_id"],
  data() {
    return {
      doc: "",
      doc_confirm: "",
      top: '',
      name: '',
      dateFormat: "yyyy-MM-dd",
      renewal: format(new Date(), "yyyy-MM-dd"),
      state: "",
      values: "",
      disabledDates: (date) => {
        const day = date;
        return day < new Date();
      },
    };
  },
  created() {
    axios.get("/states").then(({ data }) => {
      this.values = data.states;
    });

  },
  methods: {
   
    upload() {
      if (this.owner_ids) {
        this.$emit("update_license_confirm", {
          doc_confirm: this.doc_confirm,
          name: this.name,
          license_index: this.license_index,
        });
        return;
      }
      if (this.state) {
        this.values.forEach((element) => {
          if (element.id === this.state) {
            this.$emit("update_license_confirm", {
              doc_confirm: this.doc_confirm,
              renewal: this.renewal,
              state_id: this.state_id,
              license_index: this.license_index,
            });
          }
        });
      } else {
        this.$emit("update_license_confirm", {
          doc_confirm: this.doc_confirm,
          renewal: this.renewal,
          state_id: this.state_id,
          license_index: this.license_index,
        });
      }
    },
    previewFilesConfirm(event) {
      this.doc_confirm = this.$refs.fileConfirm.files[0];
      //console.log(this.doc_confirm);
    },
    validate(data) {
      this.renewal = data;
    },
  },
  computed: {
    isDisable() {
      if (this.owner_ids) {
        return !this.doc || !this.name;
      }
      if (this.single || this.type === "file") {
        return !this.doc;
      } else {
        if (this.type === "partial") {
          return !this.doc_confirm || !this.renewal;
        } else {
          return !this.doc || !this.renewal || !this.state || !this.doc_confirm;
        }
      }
    },
  },
};
</script>

<style>
.top-label .el-form-item {
    flex-direction: column;
}

.top-label .el-form-item--large .el-form-item__label {
    justify-content: flex-start;
    line-height: 1;
    height: auto;
    margin-top: 7px;
    font-size: 16px;
    color: #000;
    margin-bottom: 9px;
}
.position-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
}
</style>