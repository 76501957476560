<template>
  <div>
    <div class="md-dialog delete md-theme-default" md-active="true">
      <div class="md-dialog-container" tabindex="-1">
        <div class="md-dialog-content md-theme-default">
          {{ text }}
        </div>
        <div class="md-dialog-actions">
          <el-button @click="$emit('cancel')" class="md-primary border-0">Cancel</el-button>
          <el-button @click="$emit('confirm')" class="md-primary border-0">Yes, Confirm</el-button>
        </div>
      </div>
    </div>
    <div class="md-overlay md-fixed md-dialog-overlay"></div>
  </div>
</template>

<script>


export default {
  props: ["text"],
};
</script>

<style lang="scss">
.right {
  position: absolute;
  right: 0px;
  top: 150px;
  z-index: 100;
  max-width: 300px;
  margin-right: 0;
  transform: translate(-7%, -50%);
}
</style>
