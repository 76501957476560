import { createApp } from "vue";
import  Vue from "vue";
import EventBus from "@/eventBus";
import { useRoute, useRouter } from 'vue-router';
import App from "./App.vue";
import ElementPlus from "./plugins/element-plus";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import DateRange from 'vue3-daterange-picker';
import IdleVue from "idle-vue";
import VueGoogleCharts from "vue-google-charts";
import { BootstrapVue3 } from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import { nextTick } from "vue";
//styles

import "./scss/main.scss";
// Components
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Input from "./components/form/Input";
import Select from "./components/form/Select";
import DateRangeCalendar from "./components/form/DateRange";
import FakeSelect from "./components/form/FakeSelect";
import Pagination from "./components/Pagination.vue";
import HeadNavigation from "./components/HeadNavigation.vue";
import EmptyState from "./components/EmptyState.vue";
import Loading from "./components/Loading.vue";
import Print from "./components/Print.vue";
import Tooltip from "./components/Tooltip.vue";
import Docs from "./components/owner/RenewalDocuments.vue";
import Tabs from "./components/owner/reports/Tabs.vue";
import ConfirmPopup from "./components/ConfirmPopup.vue";
import DocumentDisplay from "./components/DocumentDisplay.vue";
import LicenseDisplay from "./components/LicenseDisplay.vue";



// Create the Vue app
const app = createApp(App);




// Register global components
app.component("navbar", Navbar);
app.component("sidebar", Sidebar);
app.component("input-component", Input);
app.component("select-component", Select);
app.component("date-range", DateRangeCalendar);
app.component("f-select-fake", FakeSelect);

app.component("print", Print);
app.component("tooltip", Tooltip);
app.component("pagination", Pagination);
app.component("headnavigation", HeadNavigation);
app.component("empty-state", EmptyState);
app.component("loading", Loading);

app.component("renewal-doc", Docs);
app.component("reports-nav", Tabs);
app.component("confirm-popup", ConfirmPopup);
app.component("doc-display", DocumentDisplay);
app.component("license-display", LicenseDisplay);
// Use plugins
app.use(VueAxios, axios);
app.use(VueGoogleCharts);



app.use(router);
app.use(BootstrapVue3);



axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// Set up Axios
//axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:8000'; // Add fallback for local development

// Set Axios in global properties to make it available in components
app.config.globalProperties.$axios = axios;

// Axios default settings (only set once)
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.auth.logout(); // Assuming window.auth.logout exists
    }
    return Promise.reject(error);
  }
);

// Initialize global objects
import Api from "./api.js";
import Auth from "./auth.js";

window.api = new Api();
window.auth = new Auth(axios, EventBus);
window.route = useRoute();
window.router = useRouter();
window.EventBus = EventBus;
app.use(ElementPlus);
// Mount the Vue app
app.mount("#app");
//console.log("Axios instance:", axios);
// Check axios baseURL after app is mounted
nextTick(() => {
  //console.log("Axios baseURL:", axios.defaults.baseURL);
});

