
const agentRoutes = [
    {
        path: '/agent/slip',
        name: 'slip',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "slip" */ '../views/agent/Slip.vue')
    },
    {
        path: '/agent/slip/:deposit_id',
        name: 'slip-details',
        meta: { middlewareAuth: true},
        component: () => import(/* webpackChunkName: "slip-d" */ '../views/agent/SlipDetails.vue')
    },
    {
        path: '/agent/slip/:deposit_id/info',
        name: 'slip-full',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "slip-f" */ '../views/agent/FullSlip.vue')
    }
]

export default agentRoutes;