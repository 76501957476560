<template>
  <div class="d-inline-block">
    <el-button class="border-0 button-text">
      <template>
        {{text}}
        <i class="fas fa-chevron-down pl-1"></i>
      </template>
    </el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: ""
    };
  },
  mounted() {
    let data = window.localStorage.getItem("data");
    let data_parsed = data ? JSON.parse(data) : null;
    this.text=data_parsed?.name;
  }
};
</script>