<template>

  <div v-if="ready" id="app">
    <template v-if="authenticated && isPasswordResetted">
      <div>
        <password-view @updated="success" />
      </div>
    </template>
    <template v-else>
      <template  class="app-pages" v-if="authenticated && store_agent !== 'agent' && store_agent !== 'super_admin' && store_agent !== 'compliance_company'">
        <navbar class="app-pages" />

        <div class="page-container app-pages">
          <el-container class="layout-container-demo page-content-wrapper pt-0 pb-0">
            <div width="250px" v-if="menuVisible" :class="{ sidebarActive: menuVisible }" >
                <el-button
                  class="md-icon-button md-dense float-right mt-3 d-md-none border-0"
                  @click="toggleMenu"
                >
                  <i class="fas fa-times"></i>
                </el-button>
                <sidebar />
            </div>
            <el-container class="pt-0 pb-0 flex-column flex-lg-row align-items-baseline">
              <el-button
                class="md-icon-button border-0 black-toggle"
                @click="toggleMenu"
                v-if="!menuVisible"
              >
                <i class="fas fa-bars"></i>
              </el-button>
              <router-view class="main-view width-full"  />
            </el-container>
          </el-container>
        </div>
      </template>
      <template v-else>
        <navbar class="app-pages" v-if="store_agent === 'agent'" />
        <router-view :class="{ 'app-pages': store_agent === 'agent' }" />
      </template>
    </template>

    <notification />
    <overlay
      :data="overlay_data"
      v-if="overlay_data"
      @close="overlay_data = false"
    />
    <ModalIdle
      :is_idle="is_idle"
      v-if="is_idle"
      @idleModalClosed="is_idle = false"
    />
  </div>
</template>

<script>

import EventBus from "./eventBus";
import Notifications from "./components/Notifications";
import Overlay from "./components/layout/Overlay";

import Password from "./views/Password";
import ModalIdle from "./components/ModalIdle";

const debounce = (callback, delay) => {
  let tid;
  return function (...args) {
    clearTimeout(tid);
    tid = setTimeout(() => {
      window.requestAnimationFrame(() => {
        callback(...args);
      });
    }, delay); // Adjust debounce delay (e.g., 100ms)
  };
};


// Extend ResizeObserver with debounce
const _ResizeObserver = window.ResizeObserver;
class DebouncedResizeObserver extends _ResizeObserver {
  constructor(callback) {
    super(debounce(callback, 2000));
  }
}
window.ResizeObserver = DebouncedResizeObserver;


export default {
  components: {
    notification: Notifications,
    overlay: Overlay,
    'password-view' : Password,
    ModalIdle
  },
  name: "Page",
  data: () => ({
    authenticated: auth.check(),
    menuVisible: true,
    user: {},
    overlay_data: false,
    store_agent: auth.getRole(),
    password:auth.getPasswordStatus(),
    ready:false,
    is_idle: false
  }),
  computed:{
      isPasswordResetted: function () {
          return this.password;
    }
  },

  onIdle() {
      if(auth.check()) {
        window.auth.logout();
        this.is_idle = true;
      }
  },
  updated() {
    EventBus.emit("app-updated");
    this.store_agent = auth.getRole();
    this.user = auth.user;
  },
  mounted() {
    window.onload = function () {
        if(!sessionStorage.getItem('logged') && auth.check()) {
            window.auth.logout();
            this.authenticated = false;
            this.user = null;
        }
    },
    setTimeout(() => {
      this.ready= true
    }, 1000);
    // Custom idle detection logic
    EventBus.on("user-idle", () => {
      if(auth.check()) {
        window.auth.logout();
        this.is_idle = true;
      }
    });
    EventBus.on("userLoggedIn", data => {
      this.authenticated = true;
      this.user = auth.user;
      this.password=!data.resetted;
      this.store_agent = auth.getRole();
    });

    EventBus.on("userLoggedOut", () => {
      this.authenticated = false;
      this.user = null;
      this.$router.push("/login");
    });
    EventBus.on("router", data => {
      this.$router.replace({ data });
    });
    EventBus.on("overlay", data => {
      this.overlay_data = data;
    });

  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    success(){
      this.password=0
    }
  }
};
</script>

<style scoped>
.page-content-wrapper{
  min-height: calc(100vh - 62px);
  display: flex;
  overflow: hidden;
  position: relative;
  background-color:#fff;
}
.width-full{
  width: 100%;
}
.black-toggle i{
  font-size: 18px;
}
@media(min-width:768px){
  .sidebarActive {
    border-right: 1px solid rgba(0, 0, 0, 0.175);
  }
}
@media(max-width:767px){

  .sidebarActive{
    box-shadow: 0 8px 10px -5px rgba(0,0,0,.2),0 16px 24px 2px rgba(0,0,0,.14),0 6px 30px 5px rgba(0,0,0,.12);
}
}
</style>
