import axios from "axios";

export const fileMixin = {
    methods: {
        async downloadFileRequest(fileUrl) {
          
            try {
                
                const response = await axios.get(fileUrl, {
                    responseType: 'blob' // Important for handling binary data
                });
                
                const contentType = response.headers['content-type'];
                const contentDisposition = response.headers['content-disposition'];

                // Determine if the file can be displayed in the browser
                const canBeOpenedInBrowser = this.isViewableFileType(contentType);
                const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
                //console.log("url", url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.getFileName(contentDisposition));
                document.body.appendChild(link);
                link.click();

                
                if (canBeOpenedInBrowser) {
                    window.open(url, '_blank');
                }

                document.body.removeChild(link);
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 30000); // Longer delay for more complex files (like PDFs)
            } catch (error) {
                console.error('Error handling the file:', error);
            }
            return false;
        },
        isViewableFileType(contentType) {
            // List of content types that can be viewed in the browser
            const viewableTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'application/pdf',
                'text/plain',
                'text/html'
            ];
            return viewableTypes.includes(contentType);
        },
        getFileName(contentDisposition) {
            if (contentDisposition) {
                const matches = /filename="([^"]*)"/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    return matches[1];
                }
            }
            return 'downloaded-file'; // Default filename
        }
    }
}
