<template>
    <div class="loading text-center m-5 p-5">
        <img src="../assets/icons/load.gif" alt="loading">
    </div>
</template>
<script>
export default {
    
}
</script>
