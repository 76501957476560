// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import authRoutes from './routes/auth';
import ownerRoutes from "./routes/owner";
import bankRoutes from "./routes/bank";
 import lmtRoutes from "./routes/lmt";
 import agentRoutes from "./routes/agent";
import emitter from '@/eventBus'; // Import the mitt emitter

// Create the router instance with history mode
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Make sure to replace this with your correct URL
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    ...authRoutes,
    ...ownerRoutes,
     ...bankRoutes,
     ...lmtRoutes,
     ...agentRoutes,
     {
      path: '/owner/profile',
      name: 'profile',
      meta: { middlewareAuth: true },
      component: () => import(/* webpackChunkName: "profile" */ './views/owner/profile/MyAccount.vue')
    },
     {
      path: '/super_admin',
      name: 'super_admin',
      meta: { middlewareAuth: true },
      component: () => import(/* webpackChunkName: "profile" */ './views/owner/Admin.vue')
    },
    {
      path: "/:pathMatch(.*)*",
      meta: { default: true },
      component: () => import(/* webpackChunkName: "404" */ './views/NotFound.vue')
    }
  ]
});


// To track history and emit events
let history = [];

// Global navigation guard
router.beforeEach((to, from, next) => {
  let role = window.auth.getRole();
  let data = window.auth.getUser();
  history.push(to);

  // Emit route change event using mitt
  emitter.emit('route', history);  // Emit the updated history

  // Force logout middleware
  if (to.matched.some(record => record.meta.middlewareLogout)) {
    if (window.auth.check()) {
      window.auth.silentLogout();
    }
  }

  if (to.fullPath === '/') {
    if (role === 'super_admin') {
      next({ path: "/super_admin" });
    } else if (role === 'compliance_company') {
      next({ path: "/compliance_company" });
    } else if (role === 'bank') {
      if (data.subrole && data.subrole.name === 'bank_teller') {
        next({ path: "/bank/reports/transaction" });
      } else {
        next({ path: "/bank/staff" });
      }
    } else if (role === 'lmt') {
      next({ path: "/lmt/staff" });
    } else if (role === 'agent') {
      next({ path: "/agent/slip" });
    }
  }

  // Role verification
  if (!to.matched.some(record => record.meta.default)) {
    let segment = to.path.split('/')[1];
    if (segment !== role) {
      next({ path: to.path.replace(segment, role) });
    }
  }

  // Authentication verification
  if (to.matched.some(record => record.meta.middlewareAuth)) {
    
    if (!window.auth.check()) {
      next({ path: '/login' });
      return;
    }
  }

  // Redirect if user is compliance company
  if (to.matched.some(record => record.meta.middlewareNotCompany)) {
    if (window.auth.getSubRole().name === 'compliance_company') {
      next({ path: '/bank/staff' });
      return;
    }
  }

  // Unauthenticated users should not access certain pages
  if (to.matched.some(record => record.meta.middlewareUnauth)) {
    
    if (window.auth.check()) {
      next({ path: '/owner/staff', query: { redirect: to.fullPath } });
      return;
    }
  }

  next();
});

export default router;
