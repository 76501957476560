<template>
  <div class="text-center empty-state">
   <i class="fas fa-box-open fa-4x	mb-4"></i>
    <h3>No data</h3><span v-if="!noretry">Please try again later</span>
  </div>
</template>

<script>
export default {
  props:['noretry']
};
</script>

<style>
.empty-state {
  font-size: 16px;
  color: #1D83C1;
  padding: 50px;
  min-height: 60vh;
  background-color: #f7f7f84f;
  border-radius: 8px;
  padding-top: 90px;
}
.empty-state h3 {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 500;
}
.empty-state a {
  font-weight: 500;
}
</style>