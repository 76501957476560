<template>

  <el-form-item 
    :label="label" 
    :prop="property" 
    v-if="values" 
    :class="{'is-error': form.err?.has(property)}"
    label-position="top"
    size="large"
    label-width="auto"
    class="flex-column"
    
  >
    <el-select
      v-model="form.formData[property]" 
      :placeholder="'Select ' + label" 
      :disabled="disabled"
      size="large"
      @change="handleChange"
    >
      <el-option
        v-for="(item, index) in values"
        :key="index"
        :label="item[display]"
        :value="item[object || 'id']"
      />
    </el-select>
    <span class="el-form-item__error">{{ form.err?.get(property) }}</span>
  </el-form-item>
</template>

<script>
export default {
  props: {
    label: String,
    form: Object,
    property: String,
    values: Array,
    display: String,
    object: String,
    disabled: Boolean
  },
  methods: {
    handleChange(value) {
      // Update the form value and clear errors for this property
     this.form?.err?.clear(this.property);
      this.$emit('update', { property: this.property, value : value });
    }
  }
};
</script>
