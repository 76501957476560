<template>
  <div class="no-print">
    <b-navbar toggleable="lg" class="pl-4 pr-4 pr-md-0">
      <b-navbar-brand>
        <router-link to="/">
          <div class="d-inline-block weight-500">Deposit</div>Slips
        </router-link>
      </b-navbar-brand>

      <div class="d-inline-block ml-5 pl-5 sub-text">
        <div class="d-flex justify-content-between ml-2">
          <div class="d-flex justify-content-start flex-column">
            <div>
              <div class="d-inline-block black-text">Get Help:</div>1-800-617-5410
            </div>
            <div>admin@depositslipsllc.com</div>
          </div>
        </div>
      </div>

      <b-navbar-toggle class="sm-hide" target="nav-collapse"></b-navbar-toggle>

      <b-collapse class="sm-hide" id="nav-collapse" is-nav>
        <b-navbar-nav v-if="role !== 'agent' && subrole.name !== 'bank_teller'" class="ml-auto my-1">
          <!-- Notifications -->
          <el-dropdown trigger="click">
            <el-button v-if="subrole.name!='compliance_company'" @click="read" class="p-0 no-hover menu-badge border-0">
              
              <el-badge  v-if="notifications.notif_number" :value="notifications.notif_number" class="item">
                <el-button class="no-hover border-0 p-0">
                  <img src="../../assets/icons/btnNotificationsOwner.svg" alt="notification" />
                </el-button>
              </el-badge>
              <el-button v-else class="border-0 no-hover  p-0">
                <img src="../../assets/icons/btnNotificationsOwner.svg" alt="notification" />
              </el-button>
            </el-button>

          <template #dropdown>
            <el-dropdown-menu class="custom-dropdown-menu">
              <span class="menu-title my-2 font-weight-bold d-block">Notifications</span>
              <template v-if="notifications.notifications && Object.keys(notifications.notifications).length !== 0">
                <div v-for="(value, index) in notifications.notifications" :key="index">
                  <div class="menu-desc ml-3 mb-2">
                    <span>{{ index }}</span>
                  </div>
                  <el-dropdown-item v-for="(item, index1) in value" :key="index1" class="flex-column align-items-start row-content">
                    <template v-if="item.lmt_request && item.red_flag_type_id !== 17">
                      <router-link v-if="role === 'bank'" :to="`/bank/lmt/create/${item.lmt_request.bank_lmt_id}`">{{ item.warning }}</router-link>
                      <router-link v-else :to="`/owner/lmt/create/${item.id}`">{{ item.warning }}</router-link>
                    </template>
                    <span v-else>
                      {{ item.warning }}
                      <a v-if="item.reminder_show" @click="reminder(item.id)" class="cursor-pointer">
                        Send Reminder <span v-if="item.reminders_sent">({{ item.reminders_sent }})</span>
                      </a>
                    </span>
                  </el-dropdown-item>
                </div>
              </template>
              <template v-else>
                <div class="line"></div>
                <div class="text-center empty-state-notification">
                  <i class="fas fa-box-open fa-4x mb-4"></i>
                  <div>No new notifications</div>
                </div>
              </template>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        </b-navbar-nav>

        <b-navbar-nav :class="{ 'ml-auto': role === 'agent' || subrole.name === 'bank_teller' }" class="my-1">
          <!-- User Dropdown -->
          <el-dropdown @command="handleCommand" trigger="click" class="user-dropdown">
            <el-button class="user-btn border-0 py-0 hover-bg-transparent">
              <div class="d-flex">
                <div class="row-content f-s-16 text-left mr-4 w-90 font-weight-normal">
                  <div>{{ name }}</div>
                  <div class="sub-text f-s-12 pt-1">{{ user.email }}</div>
                </div>
                <i class="fas fa-chevron-down mt-2 primary-color"></i>
              </div>
            </el-button>
            <template #dropdown>
                <el-dropdown-menu style="width: 280px;padding-left: 15px;padding-right: 15px;" class="custom-font-size">
                <el-dropdown-item style="padding:10px 0px;">
                  <div class="menu-title mb-2 ml-0 pl-0">
                    <span>Settings</span>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="role === 'owner'" class="justify-content-between" style="font-size:16px;padding:10px 0px;" @click.native.prevent>
                  <router-link to="/owner/profile">Edit Profile</router-link>
                  <img src="../../assets/icons/iconDropDownEdit.svg" alt="edit" />
                </el-dropdown-item>
                <el-dropdown-item v-if="role === 'bank'" style="font-size:16px;padding:10px 0px;" @click.native.prevent>
                  <router-link to="/bank/profile">Bank Profile</router-link>
                </el-dropdown-item>
                <el-dropdown-item v-if="subrole.name !== 'compliance_company'" style="padding:10px 0px;font-size:16px;border-top:1px solid rgba(1, 44, 87, 0.1);border-bottom:1px solid rgba(1, 44, 87, 0.1);" class="justify-content-between" @click.native.prevent>
                  <a @click="reset">Reset Password</a>
                  <img src="../../assets/icons/iconDropDownPassword.svg" alt="edit" />
                </el-dropdown-item>
                <el-dropdown-item class="justify-content-between" style="padding:10px 0px;font-size:16px;"><a @click="logout">Log out</a> <img src="../../assets/icons/iconLogout.svg" alt="edit" /></el-dropdown-item>
              </el-dropdown-menu>
          </template>
          </el-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElButton,
  ElBadge,
} from "element-plus";
import axios from "axios";
import Event from "@/eventBus"; // Replace with Mitt or any event emitter

export default {
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElButton,
    ElBadge,
  },
  data() {
    return {
      toggleCard: false,
      toggleCard1: false,
      user: window.auth.getUser(),
      name: window.auth.getName(),
      notifications: [],
      role: window.auth.getRole(),
      subrole: window.auth.getSubRole(),
    };
  },
  methods: {
    reset() {
      axios
        .post(`/password-forgot?email=${encodeURIComponent(this.user.email)}`)
        .then(({ data }) => {
          Event.emit("message", [data.message]);
        });
    },
    logout() {
      window.auth.logout();
    },
    read() {
      axios.post("/notifications/read").then(() => {
        // Optionally handle success here
      });
    },
    reminder(id) {
      axios
        .post(`/notification-reminder?notification_id=${id}`)
        .then(({ data }) => {
          Event.emit("message", ["Reminder sent."]);
          this.fetchNotifications();
        });
    },
    handleCommand(command) {
      if (command === "logout") {
        this.logout();
      }
    },
    fetchNotifications() {
      axios.get("/notifications").then(({ data }) => {
        this.notifications = data;
      });
    },
  },
  mounted() {
    Event.on("user_update", () => {
      this.user = window.auth.getUser();
      this.name = window.auth.getName();
    });

    Event.on("app-updated", () => {
      if (window.auth.check() && this.role !== "agent") {
        this.fetchNotifications();
      }
      this.role = window.auth.getRole();
    });
  },
};
</script>


<style>
.empty-state-notification {
    font-size: 16px;
    color: #1D83C1;
    padding: 50px 30px;
    background-color: #f7f7f84f;
    border-radius: 8px;
}
.hover-bg-transparent.el-button:hover{
  background-color:transparent !important;
}
.el-dropdown-menu__item:not(.is-disabled):focus {
    background-color: transparent !important;
    
}

.custom-dropdown-menu {
  width: 250px; /* Set the width */
  height: 300px; /* Set the height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Hide horizontal overflow */
}

.custom-dropdown-menu li span {
    white-space: initial;
    font-size: 12px;
    line-height: 18px !important;
    color: #012c57;
}
.custom-dropdown-menu li:hover,
.custom-dropdown-menu li span:hover{
  color: #012c57;
}
.no-hover span{
  padding: 0 !important;
}
.el-button.no-hover:hover {
    background: transparent !important;
}
.el-badge__content {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0 !important;
}
</style>
