<template>
  <div class="d-inline-block">
    <template v-for="(item, index) in flow" :key="index">
      <span class="page-title">
        <template v-if="item.link">
          <!-- Use router-link for navigation if history is false -->
          <router-link v-if="!history" :to="item.link">{{ item.text }}</router-link>
          <!-- Use an anchor tag and custom go function if history is true -->
          <a v-else @click="go(index)">{{ item.text }}</a>
        </template>
        <template v-else>
          <!-- Display the text if there is no link -->
          {{ item.text }}
        </template>
      </span>
      <!-- Show the "Next Page" icon if item has a link -->
      <img v-if="item.link" :key="'img' + index" src="../assets/icons/iconNextPage.svg" class="mx-1 mt-less-2" alt="Next Page Icon" />
    </template>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
  props: {
    flow: {
      type: Array,
      required: true,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();

    // `go` function to navigate back in history
    const go = (index) => {
      const totalSteps = props.flow.length - index + 1;
      //console.log("Steps to navigate:", totalSteps);
      router.go(-totalSteps);
    };

    return {
      go,
    };
  },
};
</script>

<style scoped>
.mx-1 {
  margin: 0 0.25rem;
}
.mt-less-2{
  margin-top: -2px !important;
}
</style>
