<template>
  <div class="transaction-ovarlay">
    <div class="transaction-details">
      <span @click="close">
        <i class="fas fa-times primary-color cursor-pointer float-right mt-4"></i>
      </span>
      <div class="form-section my-1">
        <img class="mr-2 height-50" src="../../assets/icons/iconGeneralInfo.svg" alt />
        General Info
      </div>
      <b-row v-if="details['General Info']">
        <b-col md="12">
          <div class="d-flex">
            <div class="gray-text">CTR</div>
            <div class="d-inline-block ml-auto">
              <a
                v-if="details['General Info']['CTR']"
                href="javascript:;"
                @click="downloadFileRequest(details['General Info']['CTR'])"
              >
                <img
                  class="mr-2 height-25"
                  src="../../assets/icons/iconUploadDocumentPopup.svg"
                  alt
                />
                View Document
              </a>
              <span v-else>-</span>
            </div>
          </div>
          <div class="d-flex">
            <div class="gray-text">ID</div>
            <div class="d-inline-block ml-auto">
              <a
                v-if="details['General Info']['ID']"
                href="javascript:;"
                @click="downloadFileRequest(details['General Info']['ID'])"
              >
                <img
                  class="mr-2 height-25"
                  src="../../assets/icons/iconUploadDocumentPopup.svg"
                  alt
                />
                View Document
              </a>
              <span v-else>-</span>
            </div>
          </div>
           <div class="d-flex">
            <div class="gray-text">LMT</div>
            <div class="d-inline-block ml-auto">{{details['General Info']['LMT']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Agent</div>
            <div class="d-inline-block ml-auto">{{details['General Info']['Agent']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Date</div>
            <div class="d-inline-block ml-auto">{{details['General Info']['Date']}}</div>
          </div>
        </b-col>
        <b-col md="12">
          <div class="d-flex">
            <div class="gray-text">Deposit</div>
            <div class="d-inline-block ml-auto">{{details['General Info']['Deposit']}}</div>
          </div>
        </b-col>
      </b-row>
      <hr class="my-2" />
      <div class="form-section my-1">
        <img class="mr-2 height-50" src="../../assets/icons/iconContact.svg" alt />
        Sender Info
      </div>
      <b-row v-if="details['Sender Info']">
        <b-col md="12">
          <div class="d-flex">
            <div class="gray-text">Sender Name</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['Sender Name']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">ID Code</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['ID Code']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">ID Type</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['ID Type']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">System ID</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['System ID']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Address</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['Address']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">City</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['City']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">State</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['State']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Zip</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['Zip']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Phone</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['Phone']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">DOB</div>
            <div class="d-inline-block ml-auto">{{details['Sender Info']['DOB']}}</div>
          </div>
        </b-col>
      </b-row>
      <hr class="my-2" />
      <div class="form-section my-1">
        <img class="mr-2 height-50" src="../../assets/icons/iconRecieverInfo.svg" alt />
        Receiver Info
      </div>
      <b-row v-if="details['Receiver Info']">
        <b-col md="12">
          <div class="d-flex">
            <div class="gray-text">Receiver Name</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['Receiver Name']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">ID Code</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['ID Code']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">System ID</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['System ID']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Address</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['Address']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">City</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['City']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">State</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['State']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Zip</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['Zip']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Country</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['Country']}}</div>
          </div>

          <div class="d-flex">
            <div class="gray-text">Phone</div>
            <div class="d-inline-block ml-auto">{{details['Receiver Info']['Phone']}}</div>
          </div>
        </b-col>
      </b-row>
      <hr class="my-2" />
      <div class="form-section my-1">
        <img class="mr-2 height-50" src="../../assets/icons/iconBankInfo.svg" alt />
        Bank Info
      </div>
      <b-row v-if="details['Bank Info']">
        <b-col md="12">
          <div class="d-flex">
            <div class="gray-text">Bank</div>
            <div class="d-inline-block ml-auto">{{details['Bank Info']['Bank']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Bank Code</div>
            <div class="d-inline-block ml-auto">{{details['Bank Info']['Bank Code']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Bank Branch</div>
            <div class="d-inline-block ml-auto">{{details['Bank Info']['Bank Branch']}}</div>
          </div>
          <div class="d-flex">
            <div class="gray-text">Bank Account</div>
            <div class="d-inline-block ml-auto">{{details['Bank Info']['Bank Account']}}</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {fileMixin} from '@/mixins/fileMixin';
export default {
  mixins: [fileMixin],
  props: ["data"],
  data() {
    return {
      details: {}
    };
  },
  created() {
    axios
      .get(
        "/reports/transaction-report/transaction?transaction_id=" + this.data.id
      )
      .then(({ data }) => {
        this.details = data;
      });
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>