<template>
  <div :key="top" class="position-fixed" v-bind:style="{ top: top+'px', 'z-index': 100}">
    <div class="md-dialog upload-doc-multiple md-theme-default" md-active="true">
      <div class="md-dialog-container" tabindex="-1">
        <div class="md-dialog-content md-theme-default">
          <span class="popup-title">Upload Documents</span>
          <span class="float-right cursor-pointer" @click="$emit('cancel')">
            <i class="fas fa-timesmd-size-1x"></i>
          </span>
          <b-row class="clearfix px-3 mt-2">
              <b-col md="6">
                <div class="popup-subtitle mb-1">Banking License</div>
                <div class="doted p-2">
                  
                  <span class="d-inline-block p-1">
                      <img src="../../assets/icons/iconUploadDocumentPopup.svg" alt="icon" class="m-1" style="max-width: 25px;" />
                      You can upload your <strong>Banking License</strong> here.
                      
                    <div class="mt-2">
                      <label for="fileElem" class="cursor-pointer primary-color d-block">
                     
                        <span v-if="!doc">Choose File</span>
                        <span class="wrap-text-multiple" v-else>{{doc.name}}</span>
                        <input type="file" id="fileElem" ref="file" @change="previewFiles" />
                      </label>
                    </div>
                  </span>
                </div>
              </b-col>
              
              <b-col md="6" style="">
                <div class="popup-subtitle mb-1">License Confirmation</div>
                <div class="doted p-2">
                 
                  <span class="d-inline-block p-1">
                       <img src="../../assets/icons/iconUploadDocumentPopup.svg" alt="icon" class="m-1" style="max-width: 25px;" />
                      You can upload your <strong>License Confirmation</strong> here.
                    <div class="mt-2">
                      <label for="fileElemConfirm" class="cursor-pointer primary-color d-block">
             
                        <span v-if="!doc_confirm">Choose File</span>
                        <span class="wrap-text-multiple" v-else>{{doc_confirm.name}}</span>
                        <input type="file" id="fileElemConfirm" ref="fileConfirm" @change="previewFilesConfirm" />
                      </label>
                    </div>
                  </span>
                </div>
              </b-col>
          </b-row>
          
            <b-row class="px-3" v-if="owner_ids">
            <b-col md="12">
             <div>
              <label>Owner</label>
              <input class="form-control"  v-model="name"></input>
             </div>
            </b-col>
            </b-row>
          <b-row class="px-3 mt-3" v-if="!single && type!='file' && !owner_ids">
            <b-col md="6" class="top-label">
              <el-form-item label="Renewal Date" size="large">
                <el-date-picker
                  v-model="renewal"
                  name="renewal"
                  :disabled-date="disabledDates"
                  type="date"
                  placeholder="Select Renewal Date"
                  @change="validate"
                  size="large"
                ></el-date-picker>
              </el-form-item>
            </b-col>
            <b-col md="6" v-if="type != 'partial'">
              <div>
                <label for="state">State</label>
                <select class="form-select" name="state" id="state" v-model="state">
                  <option
                    v-for="(item, index) in values"
                    :key="index"
                    :value="item.id"
                  >{{item.name}}</option>
                </select>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="md-dialog-actions pr-5 pb-3">
          <el-button type="primary" size="large" @click="$emit('cancel')" class="border-0 md-primary">Cancel</el-button>
          <el-button type="primary" size="large"
            @click="upload"
            :disabled="single?isDisable:isDisable||!renewal"
            class="border-0 md-button md-primary md-raised c-primary pl-2 md-theme-default"
          >Upload</el-button>
        </div>
      </div>
    </div>
    <div class="md-overlay md-fixed md-dialog-overlay"></div>
  </div>
</template>
<script>
import axios from 'axios';
import format from "date-fns/format";
export default {
  props: ["single", "type",'owner_ids'],
  data() {
    return {
      doc: "",
      doc_confirm: "",
      top: '',
      name:'',
      dateFormat: "yyyy-MM-dd",
      renewal: format(new Date(), "yyyy-MM-dd"),
      state: "",
      values: "",
      disabledDates: date => {
        const day = date;
        return day < new Date();
      }
    };
  },
  created() {
    //load the states for filling the select box
    axios.get("/states").then(({ data }) => {
      this.values = data.states;
    });

    // setTimeout(() => {
    //   var x = document.getElementsByClassName("md-scrollbar");
    //   x.item(0).addEventListener("scroll", this.handleScroll);
    // }, 1000);

    //setInterval(() => {
    // this.top = localStorage.getItem("position");
    //}, 100);
  },
  methods: {
    // handleScroll() {
    //   var x = document.getElementsByClassName("md-scrollbar");
    //   this.top = x.item(0).scrollTop + 250;
    // },

    upload() {
      if(this.owner_ids){
         this.$emit("update", {
          doc: this.doc,
          doc_confirm: this.doc_confirm,
          name: this.name
        });
        return;
      }
      if (this.state) {
        this.values.forEach(element => {
          if (element.id == this.state) {
            this.$emit("update", {
              doc: this.doc,
              doc_confirm: this.doc_confirm,
              renewal: this.renewal,
              state: { id: this.state, name: element.name }
            });
          }
        });
      } else {
        this.$emit("update", {
          doc: this.doc,
          doc_confirm: this.doc_confirm,
          renewal: this.renewal,
          state: ""
        });
      }
    },
    previewFiles(event) {
      this.doc = this.$refs.file.files[0];
      //console.log("event",  this.doc);
    },
    previewFilesConfirm(event) {
      this.doc_confirm = this.$refs.fileConfirm.files[0];
      //console.log(this.doc_confirm);
    },
    validate(data){
      this.renewal= format(new Date(data), "yyyy-MM-dd");
    }
  },
  computed: {
    isDisable() {
      if(this.owner_ids){
        return !this.doc || !this.name
      }
      if (this.single || this.type=='file') {
        return !this.doc;
      } else {
        if (this.type == "partial") {
          return !this.doc || !this.renewal;
        } else {
          return !this.doc || !this.renewal || !this.state || !this.doc_confirm;
        }
      }
    }
  }
};
</script>

<style>
.top-label .el-form-item {
    flex-direction: column;
}

.top-label .el-form-item--large .el-form-item__label {
    justify-content: flex-start;
    line-height: 1;
    height: auto;
    margin-top: 7px;
    font-size: 16px;
    color: #000;
    margin-bottom: 9px;
}
</style>