
const authRoutes = [
    {
        path: '/login',
        name: 'login',
        meta: { middlewareUnauth: true, default: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },
    {
        path: '/password/forget',
        name: 'password-forget',
        meta: { middlewareUnauth: true, default: true },
        component: () => import(/* webpackChunkName: "password-forgot" */ '../views/auth/PasswordForget.vue')
    },
    {
        path: '/password/reset/:token',
        name: 'password-reset',
        meta: { middlewareLogout: true, default: true },
        component: () => import(/* webpackChunkName: "password-reset" */ '../views/auth/PasswordReset.vue')
    }
]

export default authRoutes;