
const ownerRoutes = [

  // {
  //   path: '/owner/profile',
  //   name: 'profile',
  //   meta: { middlewareAuth: true },
  //   component: () => import(/* webpackChunkName: "profile" */ '../views/owner/profile/MyAccount.vue')
  // },
  {
    path: '/',
    name: 'home',
    meta: { middlewareAuth: true, default: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/owner/staff/StaffAccounts.vue')
  },
  {
    path: '/owner/staff',
    name: 'staff',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/owner/staff/StaffAccounts.vue')
  },
  {
    path: '/owner/staff/create',
    name: 'staff-create',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/owner/staff/Create.vue')
  },
  {
    path: '/owner/staff/:id',
    name: 'staff-edit',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "staff" */ '../views/owner/staff/Edit.vue')
  },
  {
    path: '/owner/bank',
    name: 'banks',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks" */ '../views/owner/banks/Banks.vue')
  },
  {
    path: '/owner/bank/create',
    name: 'bank-create',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks" */ '../views/owner/banks/Create.vue')
  },
  {
    path: '/owner/bank/:id',
    name: 'bank-detail',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks-det" */ '../views/owner/banks/Details.vue')
  },
  {
    path: '/owner/bank/:id/edit',
    name: 'bank-edit',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks-edit" */ '../views/owner/banks/Edit.vue')
  },
  {
    path: '/owner/bank/:id/lmt',
    name: 'bank-lmt',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks-lmt" */ '../views/owner/banks/lmt/Lmt.vue')
  },
  {
    path: '/owner/bank/:id/:lmt/agents',
    name: 'bank-lmt-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "banks-lmt" */ '../views/owner/banks/lmt/Agents.vue')
  },
  {
    path: '/owner/lmt',
    name: 'lmt',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/owner/lmt/Lmts.vue')
  },
  {
    path: '/owner/lmt/create/:id',
    name: 'lmt-request',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/owner/lmt/Request.vue')
  },
  {
    path: '/owner/lmt/:id',
    name: 'lmt-profile',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/owner/lmt/Details.vue')
  },
  {
    path: '/owner/lmt/:id/banks',
    name: 'lmt-banks',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "lmt" */ '../views/owner/lmt/Banks.vue')
  },
  {
    path: '/owner/agents',
    name: 'agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/owner/agent/Agents.vue')
  },
  {
    path: '/owner/agents/:id',
    name: 'agent-profile',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "agents" */ '../views/owner/agent/Details.vue')
  },
  {
    path: '/owner/ctr',
    name: 'ctr',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/ctr/Ctrs.vue')
  },
  {
    path: '/owner/ctr/bank/:id',
    name: 'ctr-bank',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/ctr/Banks.vue')
  },
  {
    path: '/owner/ctr/lmt',
    name: 'ctr-lmt',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/ctr/Lmt.vue')
  },
  {
    path: '/owner/ctr/agent',
    name: 'ctr-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/ctr/Agents.vue')
  },
  {
    path: '/owner/id',
    name: 'id',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/id/Ids.vue')
  },
  {
    path: '/owner/id/bank/:id',
    name: 'id-bank',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/id/Banks.vue')
  },
  {
    path: '/owner/id/lmt',
    name: 'id-lmt',
    meta: { middlewareAuth: true, extraLoad: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/id/Lmts.vue')
  },
  {
    path: '/owner/id/agent',
    name: 'id-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "ctr" */ '../views/owner/id/Agents.vue')
  },
  {
    path: '/owner/reports/',
    name: 'reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/transactions/Report.vue')
  },
  {
    path: '/owner/reports/transaction',
    name: 'transaction-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/transactions/Report.vue')
  },



  {
    path: '/owner/reports/deposit',
    name: 'deposit-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/deposit/Report.vue')
  },
  {
    path: '/owner/reports/deposit/details/:bank_lmt_id/:deposit_type_id/:date',
    name: 'deposit-reports-agents',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/deposit/Details.vue')
  },
  {
    path: '/owner/reports/deposit/agents/:deposit_id',
    name: 'deposit-report-agent-info',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/deposit/Agents.vue')
  },
  {
    path: '/owner/reports/deposit/agents/details/:deposit_id/:agent_id',
    name: 'deposit-report-details',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/deposit/AgentsDetails.vue')
  },
  {
    path: '/owner/reports/deposit/agents/details/:deposit_id/:agent_id/info',
    name: 'deposit-report-full',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/deposit/FullDeposit.vue')
  },


  {
    path: '/owner/reports/receivable',
    name: 'receivable-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/receivable/Report.vue')
  },
  {
    path: '/owner/reports/receivable/details/:id',
    name: 'receivable',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/receivable/Details.vue')
  },
  {
    path: '/owner/reports/receivable/details/:bank_lmt_id/:bank_account_id',
    name: 'receivable-info',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/receivable/Info.vue')
  },
  {
    path: '/owner/reports/receivable/details/:bank_lmt_id/:bank_account_id/:agent_id',
    name: 'receivable-info-details',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/receivable/ReportDetails.vue')
  },

  {
    path: '/owner/reports/redflags',
    name: 'redflags-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "report" */ '../views/owner/reports/red-flags/Report.vue')
  },
  {
    path: '/owner/reports/statistics',
    name: 'statistics-reports',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "statistics" */ '../views/owner/reports/statistics/Report.vue')
  },
  {
    path: '/owner/red-flags',
    name: 'red-flags',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/owner/red-flags/Redflags.vue')
  },
  {
    path: '/owner/red-flags/:bank_lmt_id',
    name: 'red-flags-lmt',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/owner/red-flags/LMT.vue')
  },
  {
    path: '/owner/red-flags/details/:red_flag_id',
    name: 'red-flag',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "redflags" */ '../views/owner/red-flags/Redflag.vue')
  },
   //EDD
  {
    path: '/owner/edd/create',
    name: 'owner-edd-create',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "edd" */ '../views/owner/edd/Create.vue')
  },
  {
    path: '/owner/edd/print',
    name: 'owner-edd-print',
    meta: { middlewareAuth: true },
    component: () => import(/* webpackChunkName: "edd" */ '../views/owner/edd/Print.vue')
  },

]

export default ownerRoutes;