<template>
  <el-scrollbar class="no-print">
    <el-menu 
      :default-active="activeIndex" 
      :default-openeds="openMenus" 
      style="width: 250px;"
      router
    >
      <!-- Profile & Logout -->
      <el-menu-item class="p-0 d-md-none" index="/profile" v-if="role === 'owner'" @click="setActive('/profile')">
        <router-link to="/profile">Edit Profile</router-link>
      </el-menu-item>
      <el-menu-item class="p-0 d-md-none" index="logout">
        <a @click="logout">Logout</a>
      </el-menu-item>

      <!-- Main Menu Items -->
      <el-menu-item 
        class="p-0" 
        v-for="(item, index) in routes" 
        :key="index" 
        :index="item.route"
        :class="{ 'is-active': isMainActive(item.route) }"
        @click="setActive(item.route)"
      >
        <router-link :to="item.route">{{ item.name }}</router-link>
      </el-menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      routes: [],
      role: "",
      sub_role: "",
      activeIndex: this.$route.path, // Track active menu item
      openMenus: [], // Track open menu sections
    };
  },
  watch: {
    // Watch for route changes and update activeIndex
    $route(to) {
      this.activeIndex = to.path;
    }
  },
  mounted() {
    axios.get("/user/account").then(({ data }) => {
      this.role = data.user_role;
      this.sub_role = data.user.subrole ? data.user.subrole.name : "";
      let role_data = data.role_data;
      window.localStorage.setItem(
        "data",
        JSON.stringify(role_data[0] ? role_data[0] : role_data)
      );
      this.setRoutes();
      this.activeIndex = this.$route.path; // Ensure it's set on page load
    });
  },
  methods: {
    logout() {
      window.auth.logout();
    },
    setActive(route) {
      this.activeIndex = route;
    },
    isMainActive(route) {
      // Keep the main menu active if any child page is visited
      return this.$route.path.startsWith(route);
    },
    setRoutes() {
      if (this.role === "owner") {
        this.routes = [
          { route: "/owner/staff", name: "Staff Accounts" },
          { route: "/owner/bank", name: "Domestic Banks" },
          { route: "/owner/lmt", name: "LMTs" },
          { route: "/owner/agents", name: "Store Agents" },
          { route: "/owner/ctr", name: "CTRs" },
          { route: "/owner/id", name: "IDs" },
          { route: "/owner/reports", name: "Reports" },
          { route: "/owner/red-flags", name: "Red Flags" },
          { route: "/owner/edd/create", name: "EDD" },
        ];
      } else if (this.role === "bank") {
        this.routes = [
          { route: "/bank/staff", name: "Staff Accounts" },
          { route: "/bank/lmt", name: "LMTs" },
          { route: "/bank/agents", name: "Store Agents" },
          { route: "/bank/ctr", name: "CTRs" },
          { route: "/bank/id", name: "IDs" },
          { route: "/bank/reports", name: "Reports" },
          { route: "/bank/red-flags", name: "Red Flags" },
          { route: "/bank/deposit", name: "Deposit Confirmation" },
          { route: "/bank/edd/create", name: "EDD" },
        ];
      } else if (this.role === "lmt") {
        this.routes = [
          { route: "/lmt/staff", name: "Staff Accounts" },
          { route: "/lmt/deposit/create", name: "Make a Deposit" },
          { route: "/lmt/agents", name: "Store Agents" },
          { route: "/lmt/ctr", name: "CTRs" },
          { route: "/lmt/id", name: "IDs" },
          { route: "/lmt/reports", name: "Reports" },
        ];
      }
    }
  }
};
</script>

<style>
.el-menu {
    border-right: 0;
}

.el-menu li {
    width: 100%;
    height: 100%;
    display: block;
}
.el-menu li a {
    align-items: center;
    text-align: left;
    padding-left: 30px !important;
    color: #012c57 !important;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
}


.el-menu-item.is-active{
  background-color: #ecf5ff;
}
.el-menu-item.is-active a{
  color: #1d83c1 !important;
  border-radius: 0;
  padding: 9px;
}
.el-menu-item:hover a{
  color: #1d83c1 !important;
}
</style>