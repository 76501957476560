<template>
  <div class="c-notifications">

    <el-alert
      v-if="dismissCountDown"
      :title="message"
      :closable="true"
      @close="dismissCountDown = 0"
      class="c-alert"
    >
    </el-alert>
  </div>
</template>

<script>
import EventBus from "@/eventBus"; // Import the Mitt instance

export default {
  data() {
    return {
      dismissCountDown: 0,
      message: "",
    };
  },
  methods: {
    handleMessage(data) {
      this.dismissCountDown = 7;
      this.message = data[0];
      setTimeout(() => {
        this.dismissCountDown = 0;
      }, 5000);
    },
  },
  mounted() {
    if (EventBus) {
      EventBus.on("message", this.handleMessage);
    } else {
      console.error("Event instance is undefined!");
    }
  },
  beforeUnmount() {
    if (EventBus) {
      EventBus.off("message", this.handleMessage);
    }
  },
};
</script>
