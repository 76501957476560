import { reactive } from 'vue';
import axios from 'axios';
import Errors from './Errors'; // Adjust the path if necessary
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
class Form {
  constructor(data) {
    this.originalData = data;
    this.formData = reactive({ ...data }); // Reactive state for form data
    this.err = new Errors(); // Error handling class
  }

  reset() {
    for (let field in this.originalData) {
      if (Array.isArray(this.formData[field])) {
        this.formData[field] = [];
      } else {
        this.formData[field] = '';
      }
    }
    this.err.clear();
  }

  data() {
    const data = {};
    for (let property in this.originalData) {
      data[property] = this.formData[property];
    }
    return data;
  }

  post(url) {
    return this.submit('post', url);
  }

  put(url) {
    return this.submit('put', url);
  }

  delete(url) {
    return this.submit('delete', url);
  }

  submit(requestType, url) {
    //console.log("Submitting Data:", this.data());
    return new Promise((resolve, reject) => {
      axios[requestType](url, this.data())
        .then(response => {
          this.onSuccess(response.data);
          resolve(response.data);
        })
        .catch(error => {
          this.onFail(error.response?.data?.errors || {});
          reject(error.response?.data || {});
        });
    });
  }

  onSuccess(data) {
    
   // this.reset();
  }

  onFail(errors) {
    console.error('Validation Errors:', errors);
    this.err.set(errors);
  }
}

export default Form;
