const lmtRoutes = [

    /*staff */
    {
        path: '/lmt/staff',
        name: 'lmt-staff',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "staff" */ '../views/lmt/staff/StaffAccounts.vue')
    },
    {
        path: '/lmt/staff/create',
        name: 'lmt-staff-create',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "staff" */ '../views/lmt/staff/Create.vue')
    },
    {
        path: '/lmt/staff/:id',
        name: 'lmt-staff-edit',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "staff" */ '../views/lmt/staff/Edit.vue')
    },


    /*deposit */
    {
        path: '/lmt/deposit/create',
        name: 'create-deposit',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "staff" */ '../views/lmt/deposit/Create.vue')
    },
    {
        path: '/lmt/deposit/history',
        name: 'history-deposit',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "staff" */ '../views/lmt/deposit/History.vue')
    },

    /*Agents */
    {
        path: '/lmt/agents',
        name: 'lmt-agents',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "agents" */ '../views/lmt/agent/Agents.vue')
    }, {
        path: '/lmt/agents/create',
        name: 'lmt-agent-create',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "agents" */ '../views/lmt/agent/Create.vue')
    },
    {
        path: '/lmt/agents/:id',
        name: 'lmt-agent-profile',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "agents" */ '../views/lmt/agent/Details.vue')
    },
    {
        path: '/lmt/agents/:id/edit',
        name: 'lmt-agent-edit',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "agents" */ '../views/lmt/agent/Edit.vue')
    },

    /*CTRs*/

    {
        path: '/lmt/ctr',
        name: 'lmt-ctr',
        meta: { middlewareAuth: true, extraLoad: true },
        component: () => import(/* webpackChunkName: "agents" */ '../views/lmt/ctr/Ctrs.vue')
    },
    {
        path: '/lmt/ctr/agent',
        name: 'lmt-ctr-agents',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "ctr" */ '../views/lmt/ctr/Agents.vue')
    },

    /*IDs*/

    {
        path: '/lmt/id',
        name: 'lmt-id-lmt',
        meta: { middlewareAuth: true, extraLoad: true },
        component: () => import(/* webpackChunkName: "id" */ '../views/lmt/id/Ids.vue')
    },
    {
        path: '/lmt/id/agent',
        name: 'lmt-id-agents',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "id" */ '../views/lmt/id/Agents.vue')
    },

    /*Transaction report*/
    {
        path: '/lmt/reports',
        name: 'lmt-reports',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/transactions/Report.vue')
    },
    {
        path: '/lmt/reports/transaction',
        name: 'lmt-transaction-reports',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/transactions/Report.vue')
    },

    /*Deposit report*/
    {
        path: '/lmt/reports/deposit',
        name: 'lmt-deposit-reports',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/deposit/Report.vue')
    },
    {
        path: '/lmt/reports/deposit/details/:bank_lmt_id/:deposit_type_id/:date',
        name: 'lmt-deposit-reports-agents',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/deposit/Details.vue')
    },
    {
        path: '/lmt/reports/deposit/agents/:deposit_id',
        name: 'lmt-deposit-report-agent-info',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/deposit/Agents.vue')
    },
    {
        path: '/lmt/reports/deposit/agents/details/:deposit_id/:agent_id',
        name: 'lmt-deposit-report-details',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/deposit/AgentsDetails.vue')
    },
    {
        path: '/lmt/reports/deposit/agents/details/:deposit_id/:agent_id/info',
        name: 'lmt-deposit-report-full',
        meta: { middlewareAuth: true },
        component: () => import(/* webpackChunkName: "report" */ '../views/lmt/reports/deposit/FullDeposit.vue')
    },


]
export default lmtRoutes;