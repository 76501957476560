<template>
  <div class="c-pagination" v-if="meta && links">
    <div class="d-flex flex-row-column mb-5">
      <span
        class="d-inline-block pagination-gray r-ml-auto"
      >Showing {{meta.from}} to {{meta.to}} from {{meta.total}} results.</span>
      <span class="d-flex pt-2 justify-content-center ml-auto">
        <span class="mt-1">Rows per page:</span>
     


        <el-dropdown>
          <el-button class="blue-cell">
            {{ per_page }}
            <i class="fas fa-chevron-down pl-1"></i>
          </el-button>
          <template #dropdown>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click="change_per_page(10)">10</el-dropdown-item>
            <el-dropdown-item @click="change_per_page(20)">20</el-dropdown-item>
            <el-dropdown-item @click="change_per_page(30)">30</el-dropdown-item>
            <el-dropdown-item @click="change_per_page(40)">40</el-dropdown-item>
            <el-dropdown-item @click="change_per_page(50)">50</el-dropdown-item>
          </el-dropdown-menu>
        </template>
        </el-dropdown>
      </span>
      <span class="d-flex pt-2 justify-content-center r-ml-auto ">
        <span class="mt-1 d-inline-block">
          {{meta.from}}-{{meta.to}} of {{meta.total}}
          <img
            src="../assets/icons/iconPreviosPage.svg"
            class="mx-3 pointer"
            v-if="links.prev"
            v-on:click="click(meta.current_page-1)"
          />
          <img
            v-else
            class="mx-3"
            style="height: 21px; margin-top:2px"
            src="../assets/icons/iconPrev.svg"
          />
          <img
            src="../assets/icons/iconNextPage.svg"
            class="mx-3 pointer"
            v-if="links.next"
            v-on:click="click(meta.current_page+1)"
          />
          <img
            v-else
            class="mx-3"
            style="height: 21px; margin-top:2px"
            src="../assets/icons/iconNext.svg"
          />
        </span>
      </span>
    </div>

  </div>
</template>
<script>
import EventBus from "@/eventBus";
export default {
  props: ["meta", "links", "per_page"],
  data() {
    return {};
  },
  methods: {
     // Function to handle page change
     change_per_page(num) {
      EventBus.emit("change_per_page", num);
    },

    // Function to change the current page based on the clicked value
    change_click(num) {
      if (num >= 1 && num <= this.meta.last_page) {
        EventBus.emit("change", num); // Emit the event with the new page number
      } else {
        //console.log("Invalid page number");
      }
    },

    // Function for previous/next page navigation
    click(page) {
      if (page >= 1 && page <= this.meta.last_page) {
        EventBus.emit("change", page); // Emit event to handle page change
      }
    },
  }
};
</script>
<style >
.pointer {
  cursor: pointer;
}
.pagination-gray {
  color: rgba(1, 44, 87, 0.5);
  font-size: 15px;
  padding: 15px;
}
.c-pagination {
  font-size: 16px;
  height: 2px;
  border: 1px solid rgba(1, 44, 87, 0.1);
}
</style>