<template>
  <span>
    <template v-if="text">{{text}}</template>
    <template v-else>-</template>
  </span>
</template>
<script>
export default {
  props: ["text"]
};
</script>