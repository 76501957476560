<template>
<div class="position-dialog">
  <div class="el-dialog upload-doc p-4">
    <div class="el-dialog__header mr-0 pt-0">
      <span class="popup-title">Upload Document</span>
      <el-button 
        icon="el-icon-close" 
        class="float-right" 
        type="text" 
        @click="$emit('cancel')">
      </el-button>
    </div>
    <div class="upload-content">
      <div class="d-flex align-items-center doted p-3">
        <img src="../../assets/icons/iconUploadDocumentPopup.svg" alt="icon" />
        <span class="d-inline-block px-4">
          You can upload your document here.
          <div class="mt-4">
            <label for="fileElem" class="cursor-pointer primary-color">
              <span v-if="!doc">Choose File</span>
              <span class="wrap-text" v-else>{{doc.name}}</span>
              <input type="file" id="fileElem" ref="file" @change="previewFiles" />
            </label>
          </div>
        </span>
      </div>
      <b-row class="px-3" v-if="owner_ids">
        <b-col md="12">
          <el-form-item label="Owner">
            <el-input v-model="name"></el-input>
          </el-form-item>
        </b-col>
      </b-row>
      <b-row class="px-3" v-if="!single && type != 'file' && !owner_ids">
        <b-col md="6">
          <el-form-item label="Renewal Date">
            <el-date-picker
              size="large"
              v-model="renewal"
              type="date"
              placeholder="Select Renewal Date"
              :disabled-date="isDisabledDate"
              @change="validate"
            ></el-date-picker>
          </el-form-item>
        </b-col>
        <b-col md="6" v-if="type != 'partial'">
          <el-form-item label="State">
            <el-select v-model="state" size="large" placeholder="Select State">
              <el-option
                v-for="(item, index) in values"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </b-col>
      </b-row>
      </div>

      <div class="d-flex justify-content-end dialog-footer pr-5 pb-3">
        <el-button @click="$emit('cancel')" class="md-primary" type='primary'>Cancel</el-button>
        <el-button
        type='primary'
          @click="upload"
          :disabled="single?isDisable:isDisable||!renewal"
          class="md-primary"
        >Upload</el-button>
      </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import Form from '../../core/Form'
import format from "date-fns/format";
export default {
  props: ["single", "type",'owner_ids'],
  data() {
    return {
      doc: "",
      top: '',
      name:'',
      dateFormat: "yyyy-MM-dd",
      renewal: format(new Date(), "yyyy-MM-dd"),
      state: "",
      values: "",
      disabledDates: date => {
        const day = date;
        return day < new Date();
      }
    };
  },
  created() {
    //load the states for filling the select box
    axios.get("/states").then(({ data }) => {
      this.values = data.states;
    });

    setTimeout(() => {
      var x = document.getElementsByClassName("md-scrollbar");
      x.item(0).addEventListener("scroll", this.handleScroll);
    }, 1000);

    //setInterval(() => {
    // this.top = localStorage.getItem("position");
    //}, 100);
  },
  methods: {
    handleScroll() {
      var x = document.getElementsByClassName("md-scrollbar");
      this.top = x.item(0).scrollTop + 250;
    },

    upload() {
      //console.log('Upload triggered:', this.doc, this.name, this.renewal, this.state);
      if(this.owner_ids){
         this.$emit("update", {
          doc: this.doc,
          name: this.name
        });
        return;
      }
      if (this.state) {
        this.values.forEach(element => {
          if (element.id == this.state) {
            this.$emit("update", {
              doc: this.doc,
              renewal: this.renewal,
              state: { id: this.state, name: element.name }
            });
          }
        });
      } else {
        this.$emit("update", {
          doc: this.doc,
          renewal: this.renewal,
          state: ""
        });
      }
    },
    previewFiles(event) {
      const file = this.$refs.file.files[0];
      this.doc = file;
    },
    validate(data){
      this.renewal= format(new Date(data), "yyyy-MM-dd");
    }
  },
  computed: {
    isDisable() {
      if(this.owner_ids){
        return !this.doc || !this.name
      }
      if (this.single || this.type=='file') {
        return !this.doc;
      } else {
        if (this.type == "partial") {
          return !this.doc || !this.renewal;
        } else {
          return !this.doc || !this.renewal || !this.state;
        }
      }
    }
  }
};
</script>

<style>
.md-dialog.md-theme-default {
    background-color: #fff;
    background-color: var(--md-theme-default-background, #fff);
    color: rgba(0, 0, 0, 0.87);
    color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.md-dialog.md-theme-default {
    background-color: #fff;
    background-color: var(--md-theme-default-background, #fff);
    color: rgba(0, 0, 0, 0.87);
    color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.md-dialog.upload-doc {
    max-width: 500px !important;
    font-size: 16px;
}
.md-dialog {
    box-shadow: 0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12);
    min-width: 280px;
    max-width: 80%;
    max-height: 80%;
    margin: auto;
    display: flex;
    flex-flow: column;
    flex-direction: row;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10;
    border-radius: 2px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    pointer-events: auto;
    transform: translate(-50%,-50%);
    transform-origin: center center;
    transition: opacity .15s cubic-bezier(.25,.8,.25,1),transform .2s cubic-bezier(.25,.8,.25,1);
    will-change: opacity,transform,left,top;
}
.md-dialog-container {
    display: flex;
    flex-flow: column;
    flex: 1;
}
.md-dialog-content {
    padding: 0 24px 24px;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    position: relative;
}
.md-dialog-content:first-child {
    padding-top: 24px;
}
.md-dialog-actions {
    min-height: 52px;
    padding: 8px 8px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}
.position-dialog {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
}
.position-dialog .el-dialog.upload-doc {
    max-width: 500px !important;
}
</style>